<template>
  <template v-if="timeLeftInSeconds > 0">
    <div class="flex justify-between items-center gap-2">
      <p class="text-xs text-slate-600 text-left">{{ text }}</p>
      <p class="text-right font-bold text-2xl leading-5 font-mono slashed-zero">
        {{ hours }}:{{ minutes }}:{{ seconds }}
      </p>
    </div>
    <progress
      id="file"
      :max="durationInSeconds"
      :value="timeLeftInSeconds"
      class="block w-full mt-1 h-1 rounded &::-webkit-progress-bar]:rounded-full [&::-webkit-progress-value]:rounded-full [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:bg-purple-600 [&::-moz-progress-bar]:bg-purple-600"
    />
  </template>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

const props = defineProps<{
  text: string
  initialDate: string
  durationInSeconds: number
}>()

const date = computed(() => {
  if (props.initialDate) {
    return new Date(props.initialDate).getTime() / 1000
  }

  return null
})

const timeLeftInSeconds = ref(0)
const hours = computed(() =>
  String(Math.floor((timeLeftInSeconds.value % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0'),
)
const minutes = computed(() =>
  String(Math.floor((timeLeftInSeconds.value % (60 * 60)) / 60)).padStart(2, '0'),
)
const seconds = computed(() => String(Math.floor(timeLeftInSeconds.value % 60)).padStart(2, '0'))
const interval = setInterval(decrementSeconds, 1000)

function decrementSeconds() {
  if (date.value) {
    timeLeftInSeconds.value = props.durationInSeconds - (Date.now() / 1000 - date.value)
  } else {
    timeLeftInSeconds.value = 0
  }

  if (timeLeftInSeconds.value === 0) {
    clearInterval(interval)
  }
}
</script>
