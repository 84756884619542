import ky, { type BeforeRequestHook } from 'ky-universal'
import { apm } from '../apm'
import {
  getAccessToken,
  hasAccessToken,
  isValidAccessToken,
  requestRefreshToken,
  redirectToLogin,
} from '../authentication'
import { pathToRegexp } from 'path-to-regexp'
import type { Options } from 'ky'

const IGNORE_ROUTES_LIST = ['firebase/connect', 'firebase/fallback', 'auth/logout']

const baseOptions: Options = {
  prefixUrl: import.meta.env.PUBLIC_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
  timeout: 180000,
}

function removeLastSlash(path: string) {
  return path.replace(/\/$/, '')
}

const authenticatedPaths: { [key: string]: (string | RegExp)[] } = {
  'mara.com.br': ['/confirmar', '/pagamentos', '/pedidos'],
  'parceiros.mara.com.br': ['/'],
  'clientes.mara.com.br': [
    '/',
    pathToRegexp('/pagamento/:orderId'),
    '/meu-ponto-de-entrega/coordenadas',
  ],
  'localhost:3000': ['/', pathToRegexp('/pagamento/:orderId')],
}

const needRedirectToLogin = import.meta.env.SSR
  ? false
  : (authenticatedPaths?.[location.host] || []).some((path) => {
      if (typeof path === 'string') {
        return removeLastSlash(path) === removeLastSlash(location.pathname)
      } else {
        return path.test(removeLastSlash(location.pathname))
      }
    })

type BeforeRequestHookParams = Parameters<BeforeRequestHook>
const hookCheckAuth = async (request: BeforeRequestHookParams[0]) => {
  const isToIgnore = IGNORE_ROUTES_LIST.find((route) => request.url.endsWith(route))
  if (isToIgnore) {
    return
  }

  if ((!import.meta.env.SSR && !hasAccessToken()) || !isValidAccessToken()) {
    try {
      await requestRefreshToken()
    } catch (error) {
      apm.captureError(error as Error)
      if (needRedirectToLogin) {
        redirectToLogin()
      } else {
        return new Response(JSON.stringify({ data: null, error: 'Unauthorized' }), { status: 401 })
      }
    }
  }

  if (hasAccessToken()) {
    request.headers.set('Authorization', `Bearer ${getAccessToken()}`)
  }

  return request
}

export const api = ky.create({
  ...baseOptions,
  hooks: {
    beforeRequest: [hookCheckAuth],
  },
})

export const checkGoogleAuth = async (redirect: boolean = false) => {
  try {
    await ky.create(baseOptions).post('auth/refresh').json()
    return true
  } catch (error) {
    if (redirect) {
      location.replace(
        `${import.meta.env.PUBLIC_API_BASE_URL}/auth/login/google?redirect=${location.href}`,
      )
    }
  }

  return false
}
