import jwtDecode, { type JwtPayload } from 'jwt-decode'
import ky from 'ky-universal'

const debugAccessTokenKey: string = 'MARA_DEBUG_ACCESS_TOKEN'
const accessTokenKey: string = 'MARA_ACCESS_TOKEN'
const accessTokenExpirationKey = 'MARA_ACCESS_TOKEN_EXPIRATION'

let refreshPromise: Promise<ResponseAuthRefresh> | null = null

interface ResponseAuthRefresh {
  data: {
    access_token: string
    expires: number
  }
}

interface DirectusJwtPayload extends JwtPayload {
  id: string
  role: string
  app_access: boolean
  admin_access: boolean
}

export function hasAccessToken() {
  return !!localStorage.getItem(debugAccessTokenKey) || !!localStorage.getItem(accessTokenKey)
}

export function getAccessToken() {
  return localStorage.getItem(debugAccessTokenKey) || localStorage.getItem(accessTokenKey)
}

export function getAccessTokenDecoded() {
  const token = getAccessToken()

  if (!token) return null

  return jwtDecode<DirectusJwtPayload>(token)
}

function getAccessTokenExpiration() {
  return parseInt(localStorage.getItem(accessTokenExpirationKey) || '')
}

function setAccessToken(accessToken: string, expiration: number) {
  if (!accessToken || !expiration) {
    throw new Error('AccessToken or Expiration is invalid')
  }

  localStorage.setItem(accessTokenKey, accessToken)
  const value = Date.now() + expiration
  localStorage.setItem(accessTokenExpirationKey, value.toString())
}

function clearAccessToken() {
  localStorage.removeItem(accessTokenKey)
  localStorage.removeItem(accessTokenExpirationKey)
}

export function isValidAccessToken() {
  if (localStorage.getItem(debugAccessTokenKey)) {
    return true
  }

  const expiration = getAccessTokenExpiration()
  const isValid = Date.now() < expiration

  if (!isValid) {
    clearAccessToken()
  }

  return isValid
}

export async function requestRefreshToken() {
  if (refreshPromise) {
    return refreshPromise
  }

  refreshPromise = ky
    .post('auth/refresh?origin=ecommerce', {
      prefixUrl: import.meta.env.PUBLIC_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      timeout: 180000,
      json: { mode: 'cookie' },
    })
    .json<ResponseAuthRefresh>()

  try {
    const response: ResponseAuthRefresh = await refreshPromise
    setAccessToken(response.data.access_token, response.data.expires)
    refreshPromise = null
  } catch (error) {
    clearAccessToken()
    throw error
  }
}

export function redirectToLogin(redirectTo?: string) {
  const searchParams = new URLSearchParams({ redirect: redirectTo ?? location.href })
  location.replace(`https://login.mara.com.br/?${searchParams.toString()}`)
}
