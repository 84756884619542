<script lang="ts" setup>
import { useSlots } from 'vue'
import Loader from './Loader.vue'

const slots = useSlots()

interface Props {
  href?: string
  target?: string
  isLoading?: boolean
  isDisable?: boolean
  leftIcon?: any
  rightIcon?: any
  variant?: 'primary' | 'secondary' | 'link'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  isDisable: false,
  variant: 'primary',
  size: 'md',
})
</script>

<template>
  <component
    :is="href ? 'a' : 'button'"
    :href="href"
    :target="target"
    :disabled="props.isDisable || props.isLoading"
    :class="[
      'relative inline-flex justify-center items-center border border-transparent rounded-full focus:outline-none',
      props.isDisable ? 'grayscale opacity-25' : '',
      props.isLoading ? 'cursor-wait' : '',
      props.variant === 'primary'
        ? 'bg-purple-700 hover:bg-purple-600 focus:ring-purple-400 disabled:bg-purple-200'
        : '',
      props.variant === 'secondary'
        ? 'bg-red-500 hover:bg-red-600 focus:ring-red-400 disabled:bg-red-200'
        : '',
      props.variant === 'link'
        ? 'bg-transparent text-purple-700'
        : 'text-white shadow-sm focus:ring-2 focus:ring-offset-2',
      props.size === 'xs' && !!slots.default ? 'px-2.5 py-1.5 text-xs font-medium' : '',
      props.size === 'sm' && !!slots.default ? 'px-3 py-2 text-sm leading-4 font-medium' : '',
      props.size === 'md' && !!slots.default ? 'px-4 py-2 text-sm font-medium' : '',
      props.size === 'lg' && !!slots.default ? 'px-4 py-2 text-base font-medium ' : '',
      props.size === 'xl' && !!slots.default ? 'px-6 py-3 text-lg font-medium' : '',
      props.size === 'xs' && !slots.default ? 'p-1' : '',
      props.size === 'sm' && !slots.default ? 'p-1.5' : '',
      props.size === 'md' && !slots.default ? 'p-2' : '',
      props.size === 'lg' && !slots.default ? 'p-2' : '',
      props.size === 'xl' && !slots.default ? 'p-3' : '',
    ]"
  >
    <transition name="fade">
      <Loader
        v-if="props.isLoading"
        :size="24"
        :stroke-width="4"
        :color="props.variant === 'link' ? 'purple' : 'white'"
        class="absolute w-full"
      />
    </transition>

    <component
      v-if="props.leftIcon"
      :is="props.leftIcon"
      :class="[
        props.variant === 'link' ? 'text-purple-700' : 'text-white',
        props.size === 'xs' && !!slots.default ? '-ml-0.5 mr-2 h-4 w-4' : '',
        props.size === 'sm' && !!slots.default ? '-ml-0.5 mr-2 h-4 w-4' : '',
        props.size === 'md' && !!slots.default ? '-ml-1 mr-2 h-5 w-5' : '',
        props.size === 'lg' && !!slots.default ? '-ml-1 mr-3 h-5 w-5' : '',
        props.size === 'xl' && !!slots.default ? '-ml-1 mr-3 h-5 w-5' : '',
        props.size === 'xs' && !slots.default ? 'h-5 w-5' : '',
        props.size === 'sm' && !slots.default ? 'h-5 w-5' : '',
        props.size === 'md' && !slots.default ? 'h-5 w-5' : '',
        props.size === 'lg' && !slots.default ? 'h-6 w-6' : '',
        props.size === 'xl' && !slots.default ? 'h-6 w-6' : '',
      ]"
      aria-hidden="true"
    />

    <span
      class="transition-opacity duration-300"
      :class="{
        'opacity-0': props.isLoading,
        'opacity-100': !props.isLoading,
      }"
    >
      <slot />
    </span>

    <component
      v-if="props.rightIcon"
      :is="props.rightIcon"
      :class="[
        props.variant === 'link' ? 'text-purple-700' : 'text-white',
        props.size === 'xs' && !!slots.default ? 'ml-2 -mr-0.5 h-4 w-4' : '',
        props.size === 'sm' && !!slots.default ? 'ml-2 -mr-0.5 h-4 w-4' : '',
        props.size === 'md' && !!slots.default ? 'ml-2 -mr-1 h-5 w-5' : '',
        props.size === 'lg' && !!slots.default ? 'ml-3 -mr-1 h-5 w-5' : '',
        props.size === 'xl' && !!slots.default ? 'ml-3 -mr-1 h-5 w-5' : '',
        props.size === 'xs' && !slots.default ? 'h-5 w-5' : '',
        props.size === 'sm' && !slots.default ? 'h-5 w-5' : '',
        props.size === 'md' && !slots.default ? 'h-5 w-5' : '',
        props.size === 'lg' && !slots.default ? 'h-6 w-6' : '',
        props.size === 'xl' && !slots.default ? 'h-6 w-6' : '',
      ]"
      aria-hidden="true"
    />
  </component>
</template>
