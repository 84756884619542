<template>
  <div class="flex justify-center" v-if="internalLoading">
    <MaraLoader />
  </div>

  <template v-else>
    <div v-if="savedCards && savedCards.length > 0 && billingAddress">
      <form
        @submit.prevent="payWithStoredCard"
        :class="[props.formOnly ? '' : 'mt-10', 'text-left']"
      >
        <div class="relative w-full">
          <Listbox v-model="currentCreditCard" v-if="savedCards.length > 0">
            <ListboxButton
              class="relative z-60 flex gap-2 w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <MaraCreditCardBrandIcon
                v-if="currentCreditCard?.id !== 'new'"
                :brand="currentCreditCard?.brand"
                class="w-10 inline-block"
              />
              <span class="block truncate" v-if="currentCreditCard?.id !== 'new'"
                >**** **** **** {{ currentCreditCard?.lastDigits }}</span
              >
              <span class="block truncate" v-if="currentCreditCard?.id === 'new'"
                >Adicionar novo cartão</span
              >
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <PencilIcon class="w-4 text-purple-600" aria-hidden="true" />
              </span>
            </ListboxButton>
            <transition
              @after-enter="beforeEnter"
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="mt-2 rounded-lg border shadow bg-white w-full z-50 overflow-x-scroll max-h-[232px]"
                :id="`listBoxOptions-${formId}`"
              >
                <ListboxOption
                  v-slot="{ active }"
                  v-for="card in savedCards"
                  :key="card.id"
                  :value="card"
                >
                  <li
                    :class="[
                      active ? 'bg-purple-100 text-purple-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 px-4 flex items-center',
                    ]"
                  >
                    <MaraCreditCardBrandIcon
                      v-if="card.id !== 'new'"
                      :brand="card.brand"
                      class="w-10 inline-block"
                    />
                    <div class="flex flex-col ml-2 w-full">
                      <span v-if="card.id === 'new'" class="uppercase"
                        >-- Adicionar novo cartão --</span
                      >
                      <div v-else class="flex justify-between w-full">
                        <span>**** **** **** {{ card.lastDigits }}</span>
                        <TrashIcon
                          class="w-4 text-red-700"
                          @click.prevent="confirmDeleteCard(card)"
                        />
                      </div>
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </Listbox>
        </div>

        <MaraCreditCardInstallments
          v-if="
            (cardBin?.toString().length || 0) >= 6 && cardToUse && currentCreditCard?.id !== 'new'
          "
          @installment-plan-selected="setInstallmentPlan"
          :card-bin="cardBin!"
          :base-amount="getChargeAmount().toNumber()"
          :loading="internalLoading || props.loading"
        />

        <MaraButton
          v-if="currentCreditCard?.id !== 'new' && props.showSavedCardButton"
          size="lg"
          class="w-full mt-10"
          :loading="internalLoading || props.loading"
          >{{ props.buttonLabel }}
        </MaraButton>
      </form>
    </div>

    <form
      v-if="!disableRegisterNewCard && billingAddress && currentCreditCard?.id === 'new'"
      @submit.prevent="payWithNewCard"
      class="text-left mt-1"
    >
      <div class="block my-1 relative">
        <label class="block text-sm font-medium text-slate-700" for="card-number"
          >Número do cartão</label
        >
        <input
          class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md block w-full border border-gray-300 placeholder-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          required
          type="text"
          id="card-number"
          name="card-number"
          inputmode="numeric"
          autocomplete="cc-number"
          :pattern="`(${(card?.lengths || []).map((value) => `(\\D*\\d{1}){${value}}`).join('|')})`"
          placeholder="0000 0000 0000 0000"
          v-model="cardNumber"
          v-maska="cardBoundObject"
          :data-maska="
            '#'
              .repeat(Math.max(...(card?.lengths || [19])))
              .split('')
              .map((value, index) =>
                (card?.gaps || [4, 8, 12]).includes(index) ? ` ${value}` : value,
              )
              .join('')
          "
          minlength="16"
          maxlength="23"
          v-set-custom-validity="formValidityCardNumber"
          @keydown.stop
        />
        <MaraCreditCardBrandIcon :brand="card?.type" class="w-10 absolute bottom-2 right-2" />
      </div>
      <div class="my-4 flex gap-4 justify-between">
        <div class="w-1/2">
          <label class="block text-sm font-medium text-slate-700" for="expiry-date">Validade</label>
          <input
            required
            type="tel"
            id="expiry-date"
            name="expiry-date"
            class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md block w-full border border-gray-300 placeholder-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            autocomplete="cc-exp"
            placeholder="MM/AA"
            minlength="5"
            maxlength="5"
            v-model="cardExpiration"
            v-maska
            data-maska="##/##"
            ref="expirationInput"
            v-set-custom-validity="validityExpirationDate"
            @keydown.stop
          />
        </div>
        <div class="w-1/2">
          <label class="block text-sm font-medium text-slate-700" for="security-code"
            >Cód. Segurança
          </label>
          <input
            class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md block w-full border border-gray-300 placeholder-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            required
            type="text"
            v-model="cardCVV"
            id="security-code"
            name="security-code"
            autocomplete="cc-csc"
            inputmode="numeric"
            :minlength="card?.code.size || 3"
            :maxlength="card?.code.size || 4"
            :pattern="`[0-9]{${card?.code.size || '3,4'}}`"
            placeholder="3 ou 4 digitos no verso do cartão"
            @keydown.stop
          />
        </div>
      </div>
      <div class="block my-4">
        <label for="name" class="block text-sm font-medium text-slate-700"
          >Nome do titular (como escrito no cartão)</label
        >
        <input
          class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md block w-full border border-gray-300 placeholder-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          required
          type="text"
          id="name"
          name="name"
          v-model="holderName"
          autocomplete="cc-name"
          placeholder="Ana Carolina"
          minlength="6"
          maxlength="64"
          pattern="[a-zA-Z\s]+"
          @keydown.stop
        />
      </div>
      <div class="block my-4">
        <label for="name" class="block text-sm font-medium text-slate-700"
          >CPF do titular do cartão</label
        >
        <input
          type="tel"
          name="holder_tax_id"
          required
          v-model="holderTaxId"
          v-maska
          data-maska="###.###.###-##"
          id="cpf"
          class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md block w-full border border-gray-300 placeholder-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          aria-describedby="cpf-description"
          placeholder="000.000.000-00"
          @keydown.stop
          v-set-custom-validity="validateTaxId"
        />
      </div>

      <MaraCreditCardInstallments
        v-if="(cardBin?.toString().length || 0) >= 6 && !props.formOnly"
        @installment-plan-selected="setInstallmentPlan"
        :card-bin="cardBin!"
        :base-amount="getChargeAmount().toNumber()"
        :loading="internalLoading || props.loading"
      />

      <MaraButton size="lg" class="w-full mt-2" :loading="internalLoading || props.loading"
        >{{ props.buttonLabel }}
      </MaraButton>
    </form>

    <BillingAddress v-if="!billingAddress" :callback="addressUpdated" />

    <div v-if="currentCreditCard?.id === 'new' && disableRegisterNewCard" class="text-center mt-10">
      O cadastro de novos cartões de crédito está temporariamente indisponível. Por favor tente
      novamente mais tarde ou escolha outro método de pagamento.
    </div>
  </template>
</template>

<style scoped>
.radio input {
  display: none;
}

.radio input + span {
  color: #999;
  opacity: 0.7;
}

.radio input:checked + span {
  border-color: #60a;
  box-shadow: #eadafc 2px 2px 2px;
  color: #60a;
  opacity: 1;
}
</style>

<script setup lang="ts">
import Big from 'big.js'
import BillingAddress from '../../src/components/BillingAddress.vue'
import cardValidator from 'card-validator'
import MaraButton from './MaraButton.vue'
import MaraCreditCardBrandIcon from './MaraCreditCardBrandIcon.vue'
import MaraCreditCardInstallments from './MaraCreditCardInstallments.vue'
import MaraLoader from '&/components/MaraLoader.vue'
import { api } from '&/services/api'
import { apm } from '&/services/apm'
import { assembly3dsPayload, validate3ds } from '&/services/paymentsCreditCard/three3ds'
import {
  BIN_LENGTH,
  buildSpammer,
  FAILED_PAYMENT_ERROR_MSG,
  getBillingAddress,
  PaymentToUserErrors,
  type OrderChargeData,
  type StoredCreditCard,
} from '&/services/paymentsCreditCard'
import { TrashIcon } from '@heroicons/vue/solid'
import { PencilIcon } from '@heroicons/vue/outline'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { onMounted, reactive, ref, type Ref, watch } from 'vue'
import { vMaska } from 'maska'
import { validate as isValidUUID } from 'uuid'
import { v4 as uuidv4 } from 'uuid'
import type { Address } from '&/types'
import type { InstallmentPlan } from '&/types/installment-plan.types'
import { BINS_TO_EXPAND, isVoucherCard, UNSUPPORTED_BINS } from '&/services/paymentsCreditCard/bins'
import { tokenizeVoucher } from '&/services/paymentsCreditCard/pagarme/api'
import { tokenizeCredit } from '&/services/paymentsCreditCard/pagseguro/api'
import type { CardData, TokenizedCard } from '&/services/paymentsCreditCard/type'
import { isValid as isValidCpf } from '@fnando/cpf'
import { isValid as isValidCnpj } from '@fnando/cnpj'

Big.RM = Big.roundHalfEven

const props = withDefaults(
  defineProps<{
    loading: boolean
    isClubPayment?: boolean
    /** Used for club mara only for now*/
    baseValueToCharge?: number
    allowsInstallments?: boolean
    formOnly?: boolean
    showSavedCardButton?: string | boolean
    buttonLabel?: string | boolean
    checkoutValues?: any
  }>(),
  {
    allowsInstallments: false,
    formOnly: false,
    showSavedCardButton: true,
    buttonLabel: 'Pagar',
  },
)

const cardBin = ref<string | null>('')
const installmentsPlanSelected: Ref<InstallmentPlan | null> = defineModel<InstallmentPlan | null>(
  'installmentsPlanSelected',
  {
    required: false,
    default: null,
  },
)

const currentCreditCard: Ref<StoredCreditCard | undefined> = defineModel<
  StoredCreditCard | undefined
>('currentCreditCard', { required: false, default: undefined })

function extractBinAndRefreshInstallments(cardNumber: string) {
  cardNumber = cardNumber.replaceAll(/\D/g, '')
  if (!validateCardNumber(cardNumber)) {
    cardBin.value = ''
  } else {
    cardBin.value = cardNumber.slice(0, BIN_LENGTH)
  }
}

function setInstallmentPlan(installments: InstallmentPlan | null) {
  installmentsPlanSelected.value = installments
}

const emit = defineEmits<{
  (
    e: 'payWithNewCard',
    fullName: string,
    encryptedCard: string,
    securityCode: string,
    cardBin: string,
    installmentPlan?: InstallmentPlan,
    threeDSToken?: string,
  ): void
  (
    e: 'payWithStoredCard',
    cardId: string,
    installmentPlan?: InstallmentPlan,
    threeDSToken?: string,
  ): void
}>()

const internalLoading = ref(true)
const cardToUse = ref() as Ref<StoredCreditCard | undefined>
const disableRegisterNewCard = import.meta.env.PUBLIC_DISABLE_ADD_NEW_CARD.toLowerCase() === 'true'

const formId = ref(Math.round(Math.random() * 1000))
const debugVoucherNumber = '4000000000000010'
// const debugCreditNumber = '6062828598919021'

const debugAddCard = false
const cardNumber = ref(debugAddCard ? debugVoucherNumber : '')
const cardExpiration = ref(debugAddCard ? '12/26' : '')
const cardCVV = ref(debugAddCard ? '123' : '')
const holderName = ref(debugAddCard ? 'Ana Carolina' : '')
const holderTaxId = ref(debugAddCard ? '389.292.965-30' : '')
const cardBoundObject = reactive({ masked: '', unmasked: '', completed: false })

async function getMe() {
  const { data } = await api
    .get(`users/me`, {
      searchParams: { fields: 'id,first_name,last_name,email,phone,last_address,CPF' },
    })
    .json<{
      data: {
        id: string
        first_name: string
        last_name: string
        email: string
        phone: string
        last_address: any
        CPF: string
      }
    }>()
  return data
}

const me = ref(await getMe())

interface ClubMaraBillingInfo {
  delivery_point: string
  address: Address
}

async function getClubMaraBillingInfo(): Promise<ClubMaraBillingInfo> {
  const records: any = await api
    .get(`items/orders/`, {
      searchParams: new URLSearchParams({
        filter: JSON.stringify({
          user: {
            _eq: me.value.id,
          },
        }),
        meta: '*',
        limit: '1',
        page: '1',
        sort: 'date_created',
        fields: ['delivery_point', 'address'].join(','),
      }),
    })
    .json()
  return records.length === 1 ? records[0] : { address: undefined, delivery_point: undefined }
}

function getOrderId() {
  const url = new URL(window.location.toString())
  const path = url.pathname.split('/')
  return path.find((p) => isValidUUID(p))
}

async function getChargeData(): Promise<OrderChargeData | ClubMaraBillingInfo | undefined> {
  if (props.isClubPayment) {
    return await getClubMaraBillingInfo()
  }

  if (props.formOnly) {
    return {
      address: null,
      total_discount: props.checkoutValues?.discountsTotal.toString(),
      total_order_price: props.checkoutValues?.productsTotal.toString(),
      total_shipping: props.checkoutValues?.shipping.toString(),
      delivery_point: '',
    }
  }

  const orderId = getOrderId()
  const { data } = await api
    .get(`items/orders/${orderId}`, {
      searchParams: {
        fields: [
          'address',
          'total_discount',
          'total_order_price',
          'total_shipping',
          'delivery_point',
        ].join(','),
      },
    })
    .json<{ data: OrderChargeData }>()
  return data
}

const chargeData = ref(await getChargeData())

const billingAddress = ref(getBillingAddress(me.value.last_address, chargeData.value?.address))

const savedCards = ref([] as StoredCreditCard[])

function defineCardToUse(card: StoredCreditCard) {
  cardToUse.value = card
  cardBin.value = card.bin
  setInstallmentPlan(null)
}

async function loadData() {
  try {
    internalLoading.value = true
    savedCards.value = (await loadSavedCards()) || []

    if (!disableRegisterNewCard) {
      savedCards.value.push({
        cardProvider: '',
        cardToken: '',
        current: false,
        id: 'new',
        brand: '',
        bin: '',
        lastDigits: '',
        pagseguroToken: '',
        dateAuthenticated: new Date(),
      })
    }

    currentCreditCard.value =
      savedCards.value.find((c: StoredCreditCard) => c.current) || savedCards.value?.[0]
  } finally {
    internalLoading.value = false
  }
}

onMounted(async () => {
  await loadData()
})

async function loadSavedCards() {
  internalLoading.value = true

  try {
    const response: {
      total: number
      cards: StoredCreditCard[]
    } = await api.get(`backend-endpoints/credit-card-payment/saved-cards`).json()
    return response.cards
  } finally {
    internalLoading.value = false
  }
}

async function deleteCard(cardId: string) {
  internalLoading.value = true

  try {
    await api
      .patch(`items/user_stored_payment_cards/${cardId}`, {
        json: {
          active: false,
        },
      })
      .json()
  } finally {
    internalLoading.value = false
  }
}

watch(currentCreditCard, async (_) => {
  if (!!currentCreditCard.value && currentCreditCard.value?.id !== 'new') {
    defineCardToUse(currentCreditCard.value)
  } else {
    cardBin.value = ''
  }
})

watch(cardNumber, (newValue) => {
  const cleanNumber = newValue.replaceAll(/\D/g, '')
  const cardBin = cleanNumber.slice(0, BIN_LENGTH)
  if (cardBin.length === BIN_LENGTH) {
    if (UNSUPPORTED_BINS.includes(cardBin)) {
      alert('Infelizmente não aceitamos este tipo de cartão. Por favor, escolha outro.')
      cardNumber.value = ''
    } else if (BINS_TO_EXPAND.includes(cardBin)) {
      alert('Ainda não aceitamos VA. Teremos novidade ainda essa semana.')
      cardNumber.value = ''
    }
  }

  extractBinAndRefreshInstallments(cleanNumber)
})

const card: Ref<{
  niceType: string
  type: string
  patterns: Array<number[] | number>
  gaps: number[]
  lengths: number[]
  code: {
    name: string
    size: number
  }
} | null> = ref(null)

const vSetCustomValidity = {
  mounted: (el: HTMLInputElement, binding: Ref<Function>) => {
    function setCustomValidity() {
      el.setCustomValidity(binding.value(el))
    }

    el.onchange = setCustomValidity
    el.onkeyup = setCustomValidity
  },
}

async function addressUpdated(address: Address) {
  billingAddress.value = { ...address, _source: 'form' }
}

function validateCardNumber(cardNumber: string) {
  const numberValidation = cardValidator.number(cardNumber)
  card.value = numberValidation.card
  return numberValidation.isPotentiallyValid
}

function formValidityCardNumber(el: HTMLInputElement) {
  return !validateCardNumber(el.value) ? 'Cartão inválido' : ''
}

function validityExpirationDate(el: HTMLInputElement) {
  const expirationDateValidation = cardValidator.expirationDate(el.value)

  if (expirationDateValidation.isPotentiallyValid) {
    return ''
  }

  return 'Data de vencimento inválida'
}

function validateTaxId(el: HTMLInputElement) {
  if (isValidCpf(el.value) || isValidCnpj(el.value)) {
    return ''
  } else {
    return 'CPF ou CNPJ inválido'
  }
}

async function payWithStoredCard() {
  if (props.formOnly) {
    return
  }

  const transaction = apm.startTransaction('payWithStoredCard')
  const spammer = buildSpammer(transaction, {
    userId: me.value.id,
    source: 'MaraCreditCardForm',
    paymentTarget: props.isClubPayment ? 'CLUB_SUBSCRIPTION' : 'ORDER',
    paymentTargetId: props.isClubPayment ? me.value.id : getOrderId()!,
    cardType: 'saved',
    cardToUse: cardToUse.value!.id,
    cardValidated: cardToUse.value!.dateAuthenticated?.toString(),
    cardLastDigits: cardToUse.value!.lastDigits,
    cardBrand: cardToUse.value!.brand,
  })

  internalLoading.value = true

  try {
    const card = cardToUse.value!
    const cardToken = card.pagseguroToken

    const shouldValidate3ds = !card.dateAuthenticated // more logic here soon.

    spammer.span('01-before-3ds', { shouldValidate3ds })

    const tdsResult = shouldValidate3ds
      ? await validate3ds(
          assembly3dsPayload(
            getChargeAmount(),
            me.value!,
            billingAddress.value!,
            cardToken,
            undefined,
          ),
        )
      : { success: true, _note: 'card-already-authenticated' }

    const ignore3dsErrors = import.meta.env.PUBLIC_IGNORE_3DS_EXISTING_CARDS === 'true'

    spammer.span('02-3ds-result', { success: tdsResult.success, ignore3dsErrors }, { tdsResult })

    if (!ignore3dsErrors && !tdsResult.success) {
      spammer.span('03-3ds-result-end', undefined, { tdsResult })
      alert(
        `${tdsResult.errorMessage} - ${PaymentToUserErrors.ON_PAY_WITH_STORED_CARD_3DS_NOT_SUCCESS}`,
      )
      return
    }

    if (!tdsResult.success && import.meta.env.DEV) {
      alert('3DS on Sandbox does not work. Continuing anyway')
    }

    if (!installmentsPlanSelected) {
      spammer.span('04-missing-installment-end', undefined, {
        installmentsPlanSelected: installmentsPlanSelected,
      })
      alert('Selecione o parcelamento desejado')
      return
    }

    spammer.span('05-pay-with-store-card-emit-end', undefined, { tdsResult })
    //For stored cards we ignore the 3DS result. We only want to run to collect samples.
    emit(
      'payWithStoredCard',
      cardToUse.value!.id!,
      installmentsPlanSelected.value || undefined,
      tdsResult.authenticationId,
    )
    transaction?.end()
  } catch (error: any) {
    error.mara_tracer = uuidv4().substring(0, 4)
    apm.captureError(error)
    spammer.span('00-pay-with-store-card-emit-catch', { tracer: error.mara_tracer })
    transaction?.end()
    alert(
      `${FAILED_PAYMENT_ERROR_MSG} - ${error.mara_tracer} - ${PaymentToUserErrors.PAYMENT_STORED_CARD}`,
    )
    throw error
  } finally {
    internalLoading.value = false
  }
}

function buildCardData(): CardData {
  const brand = card.value?.type || 'generic'
  const cardNumber = cardBoundObject.unmasked.replaceAll(/\D/g, '')
  const [expMonth, expYear] = (cardExpiration.value || '').split('/')
  const securityCode = cardCVV.value || ''
  const cardHolderName = holderName.value || ''
  const holderDocument = holderTaxId.value?.replaceAll(/\D/g, '') || ''

  return {
    number: cardNumber,
    holderName: cardHolderName,
    holderDocument: holderDocument,
    expirationMM: expMonth,
    expirationYY: expYear,
    cvv: securityCode,
    bin: cardNumber.slice(0, BIN_LENGTH),
    lastDigits: cardNumber.slice(-4),
    brand: brand,
  } as CardData
}

async function tokenizeCard(cardData: CardData): Promise<TokenizedCard> {
  const tokenizer = isVoucherCard(cardData.bin) ? tokenizeVoucher : tokenizeCredit
  return tokenizer(cardData)
}

function storeNewCardFromForm(tokenizedCard: TokenizedCard, cardData: CardData) {
  const storedCardData: Partial<StoredCreditCard> = {
    cardProvider: tokenizedCard.cardProvider,
    cardToken: tokenizedCard.cardToken,
    bin: cardData.bin,
    brand: cardData.brand,
    current: false,
    dateAuthenticated: new Date(),
    encryptedCardData: {
      encryptedCard: tokenizedCard.cardToken,
      securityCode: cardData.cvv,
      storeCard: true,
      holder: { fullName: cardData.holderName },
      card: {
        holderTaxId: cardData.holderDocument,
      },
    },
    id: 'unsaved',
    lastDigits: cardData.lastDigits,
    // pagseguroToken - does not exist yet. So better to save this as null.
  }
  savedCards.value.unshift(storedCardData as StoredCreditCard)
  currentCreditCard.value = storedCardData as StoredCreditCard
}

async function payWithNewCard(_: Event) {
  const transaction = apm.startTransaction('payWithNewCard-v1')

  const spammer = buildSpammer(transaction, {
    userId: me.value.id,
    source: 'MaraCreditCardForm',
    paymentTarget: props.isClubPayment ? 'CLUB_SUBSCRIPTION' : 'ORDER',
    paymentTargetId: props.isClubPayment ? me.value.id : getOrderId()!,
    cardType: 'new',
  })

  internalLoading.value = true

  try {
    spammer.span('01-buildData')
    const cardData = buildCardData()

    spammer.span('02-tokenize')
    const tokenizedCard = await tokenizeCard(cardData)

    if (props.formOnly) {
      spammer.span('03-store-new-card-from-form')
      storeNewCardFromForm(tokenizedCard, cardData)
    } else {
      spammer.span('04-emit-payment')
      emit(
        'payWithNewCard',
        cardData.holderName,
        tokenizedCard.cardToken,
        cardData.cvv,
        cardData.bin,
        installmentsPlanSelected.value || undefined,
      )
    }
    spammer.span('05-finish-success')
    transaction?.end()
  } catch (error: any) {
    error.mara_tracer = uuidv4().substring(0, 4)
    apm.captureError(error)
    spammer.span('00-finish-failure', { tracer: error.mara_tracer })
    transaction?.end()
    alert(
      `${FAILED_PAYMENT_ERROR_MSG} - ${error.mara_tracer} - ${PaymentToUserErrors.PAYMENT_NEW_CARD}`,
    )
    throw error
  } finally {
    internalLoading.value = false
  }
}

function getChargeAmount() {
  if (props.isClubPayment) {
    if (!props.baseValueToCharge) {
      throw new Error('baseValueToCharge is required for club payments')
    }
    return new Big(props.baseValueToCharge)
  }
  return getOrderPayableAmount()
}

function getOrderPayableAmount() {
  const orderData = chargeData.value as OrderChargeData
  const totalOrderPrice = new Big(orderData.total_order_price)
  const totalShipping = new Big(orderData.total_shipping)
  const totalDiscount = new Big(orderData.total_discount)
  return totalOrderPrice.plus(totalShipping).minus(totalDiscount)
}

function beforeEnter(el: any) {
  const listBoxPosition = document
    .querySelector(`#listBoxOptions-${formId.value}`)!
    .getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const elementTop = listBoxPosition.top + scrollTop

  window.scrollTo(0, elementTop - 250)
}

async function confirmDeleteCard(card: StoredCreditCard) {
  if (confirm(`Tem certeza que deseja excluir o cartão final ${card.lastDigits}?`)) {
    await deleteCard(card.id)
    await loadData()
  }
}
</script>
