<script setup lang="ts">
import Big from 'big.js'
import MaraLoader from '&/components/MaraLoader.vue'
import MaraProgressCountDown from '&/components/MaraProgressCountDown.vue'
import { api } from '&/services/api'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { hasPaymentInProcessing } from '&/services/paymentsCreditCard'
import { useRoute, onBeforeRouteLeave } from 'vue-router'

const route = useRoute()
const loading = ref(true)
const processingPayment = ref(false)

async function getOrder() {
  const { data } = await api
    .get(`items/orders/${route.params.orderId}`, {
      searchParams: {
        fields: [
          'short_id',
          'status',
          'payment_status',
          'pix',
          'total_discount',
          'total_order_price',
          'total_shipping',
          'date_created',
          'cart_id',
          'user.slug',
          'order_payment_methods_accepted.postpaid'
        ].join(',')
      }
    })
    .json<{
      data: {
        short_id: string
        status: 'pending' | 'confirmed' | 'separated' | 'canceled'
        payment_status: 'paid' | 'unpaid' | 'antifraud_analysis'
        pix: string
        total_discount: string
        total_order_price: string
        total_shipping: string
        date_created: string
        cart_id: string
      }
    }>()
  return data
}

const order = ref(await getOrder())
const clearSaleId = import.meta.env.PUBLIC_CLEARSALE_FINGERPRINT
const sendToClearSale = computed(() => {
  const now = new Date()
  const orderDate = new Date(order.value.date_created)
  const maxAge = 1000 * 60 * 10
  const notify = now.getTime() - maxAge <= orderDate.getTime()

  if (notify) {
    checkUrl('https://device.clearsale.com.br/p/fp.png')
  }

  return notify
})

function checkUrl(url: string) {
  let request = new XMLHttpRequest()
  request.open('GET', url, true)
  request.send(null)
  request.onerror = () => {
    request.open(
      'GET',
      `https://web.fpcs-monitor.com.br/p/fp.png?sid=${order.value.cart_id}&app=${clearSaleId.value}&bl=1`,
      false
    )
    request.send(null)
  }
}

async function checkPayment() {
  const { data } = await api
    .get(`items/orders/${route.params.orderId}`, {
      searchParams: {
        fields: ['payment_status', 'pix'].join(',')
      }
    })
    .json<{
      data: {
        payment_status: 'paid' | 'unpaid' | 'antifraud_analysis'
        pix: string
      }
    }>()

  if (!order.value.pix && data.pix) {
    order.value.pix = data.pix
  }

  order.value.payment_status = data.payment_status
  if (data.payment_status === 'paid' || order.value.status === 'canceled') {
    redirectOnPaid(data.payment_status === 'paid')
    clearInterval(checkInterval)
  } else if (data.payment_status === 'antifraud_analysis') {
    redirectOnPaid(true)
    if (!antiFraudProcessingTimeOut && !antiFraudProcessingTimedOut.value) {
      clearInterval(checkInterval)
      checkInterval = setInterval(async () => await checkPayment(), 2000)
      antiFraudProcessingTimeOut = setInterval(() => {
        ++antiFraudProcessingTimedOut.value
        if (antiFraudProcessingTimedOut.value >= 2) {
          clearInterval(antiFraudProcessingTimeOut)
        }
      }, 30000)
    }
  }
}

let checkInterval = setInterval(async () => await checkPayment(), 5000)
const antiFraudProcessingTimedOut = ref(0)
let antiFraudProcessingTimeOut: undefined | NodeJS.Timeout

onBeforeRouteLeave(() => {
  clearInterval(checkInterval)
})

const total = computed(() => {
  if (order.value.total_order_price) {
    return new Big(order.value.total_order_price)
      .add(order.value.total_shipping)
      .minus(order.value.total_discount)
      .toNumber()
  }

  return 0
})

const redirectOnPaid = (isJustPaid: boolean = false) => {
  loading.value = true
  let redirectUrl = `https://mara.com.br/conta/`
  if (isJustPaid) {
    redirectUrl += `?paid`
  }

  redirectUrl += `#${route.params.orderId}`
  location.replace(redirectUrl)
}

const paid = computed(() => order.value.payment_status === 'paid' || total.value === 0)
const cancelled = computed(() => order.value.status === 'canceled')
const antifraudAnalysis = computed(() => order.value.payment_status === 'antifraud_analysis')

onBeforeMount(async () => {
  processingPayment.value = await hasPaymentInProcessing(route.params.orderId as string)
  if (!paid.value) {
    loading.value = false
  }
})

if (paid.value || cancelled.value) {
  redirectOnPaid(paid.value && !cancelled.value)
}

watch(paid, (newValue) => {
  if (newValue) {
    redirectOnPaid(true)
  }
})
</script>

<template>
  <div
    class="flex w-full items-center justify-center gap-0 fixed flex-grow px-2 bg-purple-800 p-2 shadow-lg z-50"
  >
    <img
      src="https://img.imageboss.me/mara/cover:contain/300x150/format:auto/assets/7c27647e-ae38-491d-ac96-29686290e6d9"
      class="h-8"
      alt="Mara"
    />
  </div>

  <div class="h-12"></div>

  <div
    class="flex flex-col justify-center items-center mt-20 w-full gap-2"
    v-if="(processingPayment || antifraudAnalysis || loading) && !paid && !cancelled"
  >
    <MaraLoader :size="42" />

    <span class="text-xl text-purple-800 font-bold mb-2 text-center px-2">
      Processando pagamento,<br />aguarde...
    </span>

    <span class="text-purple-800 mb-2 text-center px-2" v-if="antiFraudProcessingTimedOut === 1">
      Só mais um momento enquanto<br />o pagamento é verificado.
    </span>

    <span class="text-purple-800 mb-2 text-center px-2" v-if="antiFraudProcessingTimedOut === 2">
      Desculpe, isso pode levar até 2 horas.<br />Você pode fechar está janela,<br />avisaremos
      assim que for concluído.
    </span>
  </div>
  <div
    class="p-4 pt-8 text-center text-slate-600 text-lg"
    v-else-if="paid || cancelled || route.matched.some((item) => item.name === 'select-method')"
  >
    <h1 class="text-2xl text-purple-800 font-bold leading-6" v-if="!paid && !cancelled">
      Conclua seu pedido:
    </h1>
    <div
      class="text-xl text-purple-800 font-bold leading-6 mb-2 flex flex-col items-center gap-2"
      v-else
    >
      <MaraLoader :size="42" />
      <h1>Você será redirecionado,<br />aguarde...</h1>
    </div>
  </div>

  <main
    v-if="
      !paid &&
      !antifraudAnalysis &&
      !cancelled &&
      !(processingPayment || antifraudAnalysis || loading)
    "
    class="p-4 max-w-2xl m-auto"
  >
    <router-view :order="order" :total="total" :paid="paid" :enable-credit="true"></router-view>

    <div class="mt-20" v-if="order.status === 'pending' && route.name !== 'payment-later'">
      <MaraProgressCountDown
        text="Finalize seu pedido ou será cancelado automaticamente"
        :initial-date="order.date_created"
        :duration-in-seconds="7200"
      />
    </div>
  </main>

  <template v-if="sendToClearSale && clearSaleId && order">
    <noscript>
      <img
        :src="
          'https://device.clearsale.com.br/p/fp.png?sid=' +
          order.cart_id +
          '&app=' +
          clearSaleId +
          '&ns=1'
        "
        alt="fp"
      />
    </noscript>
  </template>
</template>
