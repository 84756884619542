import { api } from '&/services/api'
import { apm } from '&/services/apm'
import type Big from 'big.js'
import type { Address } from '&/types'
import { binSkips3ds, BINS_TO_SKIP_3DS } from '&/services/paymentsCreditCard/index'

/** just holds from where the address came from, used to log, debug and metrics */
export interface TaggedAddress extends Address {
  _source?: string
}

export interface BillingAddress {
  street: string
  number: string
  complement: string
  regionCode: string
  country: string
  city: string
  postalCode: number
}

export interface ThreeDsResponsePayload {
  status: ThreeDsStatus
  authenticationStatus: ThreeDsAuthenticationStatus
  id: string | undefined
}

export interface ThreeDsRequestPayload {
  data: {
    customer: {
      name: string
      email: string
      phones: [
        {
          country: number
          area: number
          number: number
          type: 'MOBILE'
        },
      ]
    }
    paymentMethod: {
      type: 'CREDIT_CARD'
      installments: number
      card: {
        id: string | undefined
        encrypted: string | undefined
      }
    }
    amount: {
      value: number
      currency: 'BRL'
    }
    billingAddress: BillingAddress
    shippingAddress: BillingAddress
    dataOnly: false
  }
}

export enum ThreeDsStatus {
  REQUIRE_CHALLENGE = 'REQUIRE_CHALLENGE',
  AUTH_FLOW_COMPLETED = 'AUTH_FLOW_COMPLETED',
  CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD',
  AUTH_NOT_SUPPORTED = 'AUTH_NOT_SUPPORTED',
}

export const threeDsStatusCodeMap: Record<ThreeDsStatus, string> = {
  [ThreeDsStatus.REQUIRE_CHALLENGE]: '01',
  [ThreeDsStatus.AUTH_FLOW_COMPLETED]: '02',
  [ThreeDsStatus.CHANGE_PAYMENT_METHOD]: '03',
  [ThreeDsStatus.AUTH_NOT_SUPPORTED]: '04',
}

export enum ThreeDsAuthenticationStatus {
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

export const threeDsAuthenticationStatusCodeMap: Record<ThreeDsAuthenticationStatus, string> = {
  [ThreeDsAuthenticationStatus.AUTHENTICATED]: '101',
  [ThreeDsAuthenticationStatus.NOT_AUTHENTICATED]: '102',
}

export interface ThreeDsResult {
  authenticationId?: string
  errorMessage?: string
  success: boolean
  _note?: string
}

export function handleResponse(authResponse: ThreeDsResponsePayload): ThreeDsResult {
  const statusCode = threeDsStatusCodeMap[authResponse.status] || 0
  const authStatusCode = threeDsAuthenticationStatusCodeMap[authResponse.authenticationStatus] || 0
  const errorCode = `${statusCode}-${authStatusCode}`
  switch (authResponse.status) {
    case ThreeDsStatus.AUTH_FLOW_COMPLETED:
      const success =
        authResponse.authenticationStatus === ThreeDsAuthenticationStatus.AUTHENTICATED
      return {
        success,
        authenticationId: authResponse.id,
        errorMessage: !success
          ? `Não foi possível autenticar seu cartão com o banco. Tente com outro cartão por gentileza. Status: ${errorCode}`
          : undefined,
      }

    default:
      return {
        success: false,
        errorMessage: `Não foi possível validar seu cartão com o Banco. Tente com outro cartão por gentileza. Erro: ${errorCode}`,
      }
  }
}

export async function validate3ds(
  payload: ThreeDsRequestPayload,
  cardBin?: string,
): Promise<ThreeDsResult> {
  const _tds = apm.startTransaction('validate3ds')
  _tds?.addLabels({
    payload: JSON.stringify(payload),
    meta: JSON.stringify({ cardBin }),
    flags: JSON.stringify({
      PUBLIC_ACTIVATE_3DS_NEW_CARDS: import.meta.env.PUBLIC_ACTIVATE_3DS_NEW_CARDS as string,
      PUBLIC_ACTIVATE_3DS_EXISTING_CARDS: import.meta.env
        .PUBLIC_ACTIVATE_3DS_EXISTING_CARDS as string,
    }),
  })

  try {
    if (
      payload.data.paymentMethod.card.encrypted &&
      import.meta.env.PUBLIC_ACTIVATE_3DS_NEW_CARDS === 'false'
    ) {
      return { success: true, _note: '3DS is disabled for new cards' }
    }

    if (
      payload.data.paymentMethod.card.id &&
      import.meta.env.PUBLIC_ACTIVATE_3DS_EXISTING_CARDS === 'false'
    ) {
      return { success: true, _note: '3DS is disabled for existing cards' }
    }

    if (cardBin) {
      if (binSkips3ds(cardBin)) {
        return { success: true, _note: 'Skipped by CARD BIN' }
      }
    }

    const sessionToken = await get3dsSessionToken()
    const env = import.meta.env.PUBLIC_PAGSEGURO_ENV

    const pagSeguroSdk = PagSeguro as any
    pagSeguroSdk.setUp({ session: sessionToken.session, env })

    const response = (await pagSeguroSdk.authenticate3DS(payload)) as ThreeDsResponsePayload
    _tds?.addLabels({
      tds_in_customer: JSON.stringify(payload.data.customer),
      tds_in_new_card: !!payload.data.paymentMethod.card.encrypted,
      tds_in_amount: payload.data.amount.value,
      tds_out_status: response.status,
      tds_out_authenticationStatus: response.authenticationStatus || 'NO-AUTH_STATUS',
      trs_out_id: response.id || 'NO-ID',
    })
    return handleResponse(response)
  } catch (e: any) {
    apm.captureError(e)
    _tds?.addLabels({ error: JSON.stringify(e, Object.getOwnPropertyNames(e)) })
    throw e
  } finally {
    _tds?.end()
  }
}

async function get3dsSessionToken(): Promise<{ session: string; expires_at: number }> {
  const sessionTokenResponse = await api.get('backend-endpoints/3ds/session-token')

  if (sessionTokenResponse.status !== 200) {
    apm.captureError({
      error: 'could-not-get-session-token',
      status: sessionTokenResponse.status,
      data: await sessionTokenResponse.json(),
    } as any)
    throw `Não foi possível validar seu cartão. Tente mais tarde novamente. ERRO: 001`
  }
  return await sessionTokenResponse.json()
}

export function assembly3dsPayload(
  chargeAmountInBrl: Big,
  user: Record<string, any>,
  address: TaggedAddress,
  cardId: string | undefined,
  encryptedCard: string | undefined,
): ThreeDsRequestPayload {
  try {
    const billingAddress = {
      street: address.street?.trim() || '',
      number: address.number?.toString().trim() || '',
      complement: address.complement?.trim() || 'SEM COMPLEMENTO',
      regionCode: address.state?.trim() || '',
      country: 'BRA',
      city: address.city?.trim() || '',
      postalCode: parseInt(address.postalcode?.replace('-', '').trim() || '00000000'),
    } as BillingAddress

    return {
      data: {
        customer: {
          name: [user.first_name, user.last_name].join(' ').trim(),
          email: user.email ?? 'unknown@unknown.com',
          phones: [
            {
              country: parseInt(user.phone?.slice(0, 2)),
              area: parseInt(user.phone?.slice(2, 4)),
              number: parseInt(user.phone?.slice(4, 13)),
              type: 'MOBILE',
            },
          ],
        },
        paymentMethod: {
          type: 'CREDIT_CARD',
          installments: 1,
          card: {
            id: cardId,
            encrypted: encryptedCard,
          },
        },
        amount: {
          value: Math.floor(chargeAmountInBrl.mul(100).toNumber()),
          currency: 'BRL',
        },
        billingAddress: billingAddress,
        shippingAddress: billingAddress,
        dataOnly: false,
      },
    }
  } catch (e: any) {
    apm.captureError(e)
    throw e
  }
}
