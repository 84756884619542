<script setup lang="ts">
import { api } from '&/services/api'
import { currencyFormat } from '&/services/format'
import { ArrowSmLeftIcon } from '@heroicons/vue/solid'
import { RouterLink, useRoute } from 'vue-router'
import MaraLoader from '&/components/MaraLoader.vue'
import { ref } from 'vue'

const route = useRoute()

const props = defineProps<{
  total: number
  order: {
    status: string
    order_payment_methods_accepted: { postpaid: boolean }[] | null
  }
}>()

const loading = ref(true)
const postpaid = ref(props.order.order_payment_methods_accepted?.[0]?.postpaid)

if (props.order.status !== 'pending' || !postpaid.value) {
  location.href = `https://mara.com.br/conta/#${route.params.orderId}`
} else if (props.order.status === 'pending' && postpaid.value) {
  await api
    .patch(`items/orders/${route.params.orderId}`, {
      searchParams: {
        fields: ['status'].join(',')
      },
      body: JSON.stringify({
        status: 'confirmed'
      })
    })
    .json<{
      data: {
        status: 'confirmed'
      }
    }>()

  location.href = `https://mara.com.br/conta/?payLater#${route.params.orderId}`
}
</script>

<template>
  <div class="flex flex-col justify-center items-center mt-12" v-if="loading">
    <MaraLoader />
    <div class="text-2xl text-purple-800 font-bold mb-2 text-center">
      Confirmando pagamento na entrega
    </div>
  </div>

  <template v-else>
    <RouterLink
      class="flex gap-1 text-purple-600 border-b border-slate-200 pb-2 mb-6"
      :to="`/pagamento/${route.params.orderId}`"
    >
      <ArrowSmLeftIcon class="h-5 w-5" /> Voltar
    </RouterLink>

    <div v-if="!postpaid">
      <div>
        Esse pedido não pode ser pago na entrega,
        <a class="text-purple-600 hover:underline" :href="`/pagamento/${route.params.orderId}`"
          >escolha outra forma de pagamento</a
        >.
      </div>
    </div>

    <div v-else>
      <div class="w-full text-center text-4xl font-bold mt-1 mb-6 text-slate-800">
        {{ currencyFormat(props.total) }}
      </div>

      <p class="font-bold mb-4">Você escolheu pagar na entrega</p>

      <p>Aceitamos PIX, cartão de débito e crédito ou vale-alimentação.</p>
    </div>
  </template>
</template>
