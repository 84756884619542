<template>
  <img
    :src="`https://img.imageboss.me/mara/cover:contain/78x50/format:auto/assets/${
      brands[brand || 'generic']
    }`"
    alt="bandeira do cartão"
  />
</template>

<script setup lang="ts">
const brands: { [key: string]: any } = {
  alelo: '9f135f81-6b4b-4e48-9316-45626750beb4',
  'american-express': '1a9eb7ee-e74d-43ca-bc5c-918218eeb0ac',
  caju: 'ed04beca-fe0f-441b-939a-c740d473d2ef',
  'diners-club': '772b1b1f-cdc5-4f4e-904b-6f9c2d5521dd',
  discover: '5afd702f-a071-4363-8696-ba137c6e2772',
  elo: '6a7b9a5a-d3b8-45a0-86bc-e85e02f2b4d5',
  flash: '7180a6f6-155f-47f5-929a-8e70f53e195d',
  hiper: '1e78a503-8952-4649-b87c-b0280cce6c31',
  hipercard: '6079e56c-c04b-4a38-b9e8-559373ae88fe',
  jcb: 'c6e99142-9a16-47c0-bec5-4f9000dd035a',
  maestro: '7b593a5d-cc50-4038-90e6-a588c3ee509b',
  mastercard: '97bffcac-7332-4ed7-a2fb-0e18b9080462',
  mir: '386f4e0f-8f81-46d6-9a82-9e3024f83080',
  pluxee: '5aab5c8d-3476-4f03-bf57-15e426675407',
  sodexo: '77bd2904-301e-42ca-8003-42560531b704',
  ticket: '93a8e3b5-748a-44d8-8ba0-0e497c20651d',
  unionpay: '79b0c39d-baaa-49f0-9f70-4b471fd94c9a',
  visa: '696e6078-2bde-42f4-9c02-a4a7d03fd24d',
  vr: '17c96c80-107a-4983-b63d-6ab98376e1bf',
  generic: '1e5de502-24ec-40c0-a3c5-cfb3322e473e',
}

defineProps<{
  brand: string | undefined
}>()
</script>
