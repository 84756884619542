<script setup lang="ts">
import PixLogo from '@/components/PixLogo.vue'
import { currencyFormat } from '&/services/format'
import { ArrowSmLeftIcon } from '@heroicons/vue/solid'
import { RouterLink, useRoute } from 'vue-router'
import MaraLoader from '&/components/MaraLoader.vue'
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { ref } from 'vue'

const route = useRoute()
const showModal = ref(false)

const props = defineProps<{
  order: {
    pix: string
  }
  total: number
}>()

const copyPix = () => {
  const text = document.querySelector('#pixValue') as any
  if (text) {
    navigator.clipboard
      .writeText(text.value)
      .then()
      .catch(() => {
        const oldContentEditable = text.contentEditable,
          oldReadOnly = text.readOnly,
          range = document.createRange()

        text.disabled = false
        text.contentEditable = true
        text.readOnly = false
        range.selectNodeContents(text)

        const s = window.getSelection()
        s?.removeAllRanges()
        s?.addRange(range)

        text.setSelectionRange(0, 999999)

        text.contentEditable = oldContentEditable
        text.readOnly = oldReadOnly

        document.execCommand('copy')
        text.disabled = true
      })
  }

  setTimeout(() => {
    showModal.value = false
  }, 2000)
}
</script>

<template>
  <RouterLink
    class="flex items-center gap-1 text-purple-600 border-b border-slate-200 pb-2 mb-6"
    :to="`/pagamento/${route.params.orderId}`"
  >
    <ArrowSmLeftIcon class="h-5 w-5" />
    <span>Outras formas de pagamento</span>
  </RouterLink>

  <div v-if="!props.order.pix" class="flex flex-col items-center">
    <MaraLoader />
    <div class="mt-2 font-semibold text-purple-800">Carregando dados do PIX</div>
  </div>
  <div v-else>
    <div class="text-sm bg-white px-4 drop-shadow-sm">
      <div class="py-4 gap-2">
        <div class="flex gap-4 items-center">
          <PixLogo class="w-24 h-9" />
          <p class="flex-1 text-sm text-slate-600">
            Utilize o <span class="font-black underline">Pix Copia e Cola</span> no aplicativo do
            banco
          </p>
        </div>
        <form
          class="flex mt-4 cursor-pointer rounded-full active:bg-slate-200"
          tabindex="0"
          @click="showModal = true"
        >
          <textarea
            id="pixValue"
            readonly
            @click="copyPix"
            class="text-xs select-none focus:ring-0 text-slate-600 bg-slate-100 font-slate-600 w-full text-ellipsis font-mono font-medium p-2 rounded-l-md border-gray-300 border-r-0 border-2 h-20"
            :value="props.order.pix"
          >
          </textarea>
          <div
            @click="copyPix"
            class="text-white bg-purple-600 active:bg-purple-700 rounded-r-md px-4 py-2 text-base font-medium inline-flex justify-center items-center"
          >
            Copiar
          </div>
        </form>
      </div>

      <hr />

      <div class="w-full text-center text-slate-800 my-5">
        <div class="text-xs font-light uppercase">Total a Pagar</div>
        <div class="text-3xl font-bold">{{ currencyFormat(props.total) }}</div>
      </div>

      <hr />

      <div class="py-4 mb-4 flexjustify-between gap-2 grid place-items-center">
        <img
          class="mx-auto mt-4 max-h-40"
          :src="`https://api.qrserver.com/v1/create-qr-code/?size=500x500&chart-source=UTF-8&data=${encodeURIComponent(
            props.order.pix
          )}`"
          height="160"
          width="160"
          alt="Pix QR Code"
        />
      </div>
    </div>
  </div>
  <TransitionRoot appear :show="showModal" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0" />
      </TransitionChild>
      <div class="fixed inset-x-0 bottom-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="div"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md bg-purple-500 transform overflow-hidden rounded-2xl p-4 text-left align-middle shadow-xl transition-all"
            >
              <div class="text-sm text-white font-bold">Pix copiado com sucesso!</div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
