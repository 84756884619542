import type { CardData, TokenizedCard } from '../type'
import { CardProvider } from '../type'

function getPubKey() {
  return import.meta.env.PUBLIC_PAGSEGURO_PUB_KEY
}

export async function tokenizeCredit(cardData: CardData): Promise<TokenizedCard> {
  const encryptedData = PagSeguro.encryptCard({
    publicKey: getPubKey(),
    holder: cardData.holderName,
    number: cardData.number,
    expMonth: cardData.expirationMM,
    expYear: `20${cardData.expirationYY}`,
    securityCode: cardData.cvv,
  })

  return {
    cardProvider: CardProvider.PAG_SEGURO,
    cardToken: encryptedData.encryptedCard,
  }
}
