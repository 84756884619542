<template>
  <div class="flex justify-center mt-2 mb-5" v-if="loading">Carregando parcelas...</div>
  <div class="flex justify-center mt-2 mb-20" v-if="loading">
    <MaraLoader />
  </div>

  <div class="mt-2" v-else>
    <div class="flex justify-between items-center mt-4 mb-1">
      <h2 class="text-slate-600 text-xs font-light">Opções de parcelamento:</h2>
    </div>

    <span v-if="installmentPlans.length > 1" class="font-bold">Selecione o parcelamento:</span>
    <fieldset aria-label="Parcelas">
      <div class="text-left mt-1">
        <label
          v-for="installmentPlan in installmentPlans"
          v-bind:key="installmentPlan.installments"
          class="radio block mb-3 p2 fill-amber-400"
          @click="selectInstallmentPlan(installmentPlan)"
        >
          <input
            type="radio"
            @click="selectInstallmentPlan(installmentPlan)"
            v-model="selectedInstallmentPlan"
            :value="installmentPlan"
            name="installmentPlan"
            class="fixed right-4 m-1"
          />
          <span
            class="block shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md w-full border border-gray-300 placeholder-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none p-2"
          >
            <span class="flex justify-between">
              <span>
                <span class="font-bold">{{ installmentPlan.installments }}x</span> de
                <span class="font-bold">{{
                  currencyFormat(installmentPlan.installment_value)
                }}</span>
              </span>
              <span>{{
                installmentPlan.total_interest > 0
                  ? `Total: ${currencyFormat(installmentPlan.total_amount)}`
                  : ' sem juros'
              }}</span>
            </span>
          </span>
        </label>
      </div>
    </fieldset>
  </div>
</template>

<style scoped>
.radio input {
  display: none;
}

.radio input + span {
  color: #999;
  opacity: 0.7;
}

.radio input:checked + span {
  border-color: #60a;
  box-shadow: #eadafc 2px 2px 2px;
  color: #60a;
  opacity: 1;
}
</style>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { currencyFormat } from '&/services/format'
import { api } from '&/services/api'
import MaraLoader from '&/components/MaraLoader.vue'
import type { InstallmentPlan, InstallmentPlansPayload } from '&/types/installment-plan.types'

const props = defineProps<{
  loading: boolean
  cardBin: string
  baseAmount: number
}>()

const loading = ref(props.loading)

const emit = defineEmits<{
  (e: 'installmentPlanSelected', installments: InstallmentPlan | null): void
}>()

async function queryAvailableInstallmentPlans(
  cardBin: string,
  baseAmount: number,
): Promise<InstallmentPlan[]> {
  try {
    loading.value = true
    emit('installmentPlanSelected', null) // Emit the selected plan to the parent component

    const response = await api
      .get('backend-endpoints/credit-card-payment/installments-plan', {
        searchParams: {
          cardBin: cardBin,
          amount: baseAmount,
        },
      })
      .json<InstallmentPlansPayload>()
    loading.value = false

    return response.plans
  } catch (error) {
    console.error('Error fetching installment plans:', error)
    loading.value = false
    return [
      {
        installments: 1,
        installment_value: baseAmount,
        total_amount: baseAmount,
        total_interest: 0,
      },
    ]
  }
}

const selectedInstallmentPlan = ref<InstallmentPlan | null>(null)

const selectInstallmentPlan = (installmentPlan: InstallmentPlan) => {
  selectedInstallmentPlan.value = installmentPlan
  emit('installmentPlanSelected', installmentPlan) // Emit the selected plan to the parent component
}

const installmentPlans = ref<InstallmentPlan[]>([])

async function fetchInstallmentPlans() {
  const plans = await queryAvailableInstallmentPlans(props.cardBin, props.baseAmount)
  installmentPlans.value = plans
  selectedInstallmentPlan.value = plans.find((p) => p.installments === 1)!
}

watch(() => props.cardBin, fetchInstallmentPlans)

onMounted(fetchInstallmentPlans)
</script>
