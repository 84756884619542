/** This file is a copy of bins.ts on Mono repository. Any changes here, should be replicated there */

export const BIN_LENGTH = 6

export const BINS_TO_SKIP_3DS = [
  '402588', //Others
  '439094', // Caju
  '457601', // Caju
  '223117', // Flash
  '522731', // Flash
]

export const VOUCHER_BINS = {
  VR: ['627416', '637036', '602651', '603342'],
  SODEXO: [
    '603389',
    '603463',
    '603628',
    '603692',
    '603693',
    '603694',
    '603695',
    '603696',
    '603697',
    '603698',
    '603699',
  ],
  TICKET: ['603628', '603693', '603694', '603695', '603696', '603697', '603698', '603699'],
  PAGARME_SANDBOX: ['400000'],
}

export const UNSUPPORTED_BINS = [
  '600818',
  '606070',
  '606068',
  '606069',
  '606071', // Pluxee Outros
]

export const BINS_TO_EXPAND: string[] = [
  // all of them where accepted right now
]

export function isVoucherCard(bin: string): boolean {
  return Object.values(VOUCHER_BINS).some((bins) => bins.includes(bin))
}
