<script lang="ts" setup>
import Button from './Button.vue'
import { api } from '&/services/api'
import { ExclamationCircleIcon } from '@heroicons/vue/outline'
import { getAddressByPostalCode } from '../services/address/postalcode'
import { ref, onMounted, watch, nextTick } from 'vue'
import { vMaska } from 'maska'
import deliveryZones from '../../data/deliveryZones.json'

const props = defineProps<{
  callback: CallableFunction
}>()

const successCallback = props.callback

async function getMe() {
  const { data } = await api
    .get(`users/me`, {
      searchParams: {
        fields: 'last_address',
      },
    })
    .json<{
      data: {
        last_address: any
      }
    }>()
  return data
}

interface Me {
  id: string
  slug: string
  phone: string
  first_name: string
  last_name: string
  email: string
  CPF: string
  last_delivery_point: string
  last_address: {
    postalcode?: string
    state?: string
    city?: string
    neighborhood?: string
    street?: string
    number?: string
    complement?: string
  }
  role?: {
    name: string
  }
  orders: string
  referrer: string
  last_referrer: string
  club_member: boolean
  enabled_for_bonus: boolean
}

const me = ref(await getMe())
const inputPostalCode = ref()
const inputNumber = ref()
const loading = ref(false)
const postalcode = ref(me.value?.last_address?.postalcode || '')
const state = ref(me.value?.last_address?.state || '')
const city = ref(me.value?.last_address?.city || '')
const neighborhood = ref(me.value?.last_address?.neighborhood || '')
const street = ref(me.value?.last_address?.street || '')
const number = ref(me.value?.last_address?.number || '')
const complement = ref(me.value?.last_address?.complement || '')
const postalCodeCovered = ref(true)

const updateMe = async (address: any) => {
  const { data } = await api
    .patch('users/me', {
      json: {
        last_address: address,
      },
      searchParams: { fields: 'last_address' },
    })
    .json<{ data: Me }>()

  return data.last_address
}

const coveredDeliveryArea = (postalCode: string) => {
  const postalCodeNumber = Number(postalCode.replace(/\D/g, ''))

  const postalCodeRanges = deliveryZones.flatMap(({ postal_code_range }) => postal_code_range)

  return postalCodeRanges.some(
    ({ start, end }) => postalCodeNumber >= start && postalCodeNumber <= end,
  )
}

watch(postalcode, async () => {
  inputPostalCode.value?.setCustomValidity('')

  if (postalcode.value.length === 9) {
    loading.value = true

    const { uf, localidade, bairro, logradouro } = await getAddressByPostalCode(postalcode.value)
    postalCodeCovered.value = !!coveredDeliveryArea(postalcode.value)

    loading.value = false

    if (uf) {
      state.value = uf || ''
      city.value = localidade || ''
      neighborhood.value = bairro || ''
      street.value = logradouro || ''
      await nextTick()
      inputNumber.value.focus()
    } else {
      inputPostalCode.value.setCustomValidity('CEP não encontrado')
      inputPostalCode.value.reportValidity()
      await nextTick()
      inputPostalCode.value.focus()
    }
  }
})

onMounted(() => {
  inputPostalCode.value.focus()
})

const onSubmit = async (_: any) => {
  loading.value = true
  try {
    const newAddress = await updateMe({
      postalcode: postalcode.value,
      state: state.value,
      city: city.value,
      neighborhood: neighborhood.value,
      street: street.value,
      number: number.value,
      complement: complement.value,
    })
    await successCallback(newAddress)
    loading.value = false
  } catch (error) {
    loading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit" class="flex-1 flex justify-between flex-col p-4 items-stretch">
    <h2 class="mb-4 uppercase font-semibold">Endereço de Cobrança</h2>
    <div
      v-if="!postalCodeCovered"
      class="bg-gray-400 p-2 rounded mb-3 text-white flex items-center"
    >
      <ExclamationCircleIcon class="w-4 h-5 text-white mr-2" />
      <span>Atenção: não entregamos nessa região</span>
    </div>
    <div class="flex gap-6 flex-col items-stretch mb-6">
      <section>
        <label for="postal-code" class="block text-sm font-medium text-slate-700">CEP</label>
        <input
          id="postal-code"
          name="postal-code"
          autocomplete="postal-code"
          maxlength="9"
          type="tel"
          required
          pattern="\d{5}-\d{3}"
          title="CEP"
          v-model="postalcode"
          v-maska
          data-maska="#####-###"
          :disabled="loading"
          placeholder="00000-000"
          class="mt-1 shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-slate-300 rounded-md"
          aria-describedby="postal-code"
          ref="inputPostalCode"
        />
      </section>

      <section>
        <label for="street" class="block text-sm font-medium text-slate-700">Endereço</label>
        <input
          id="street"
          name="street"
          autocomplete="street"
          type="text"
          required
          v-model="street"
          class="mt-1 shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-slate-300 rounded-md"
          aria-describedby="street"
        />
      </section>

      <div class="flex gap-4">
        <section class="w-1/3">
          <label for="number" class="block text-sm font-medium text-slate-700">Número</label>
          <input
            id="number"
            name="number"
            autocomplete="number"
            type="tel"
            required
            v-model="number"
            :disabled="loading"
            class="mt-1 shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-slate-300 rounded-md"
            aria-describedby="number"
            ref="inputNumber"
          />
        </section>

        <section class="w-2/3">
          <label for="complement" class="block text-sm font-medium text-slate-700"
            >Complemento</label
          >
          <input
            id="complement"
            name="complement"
            autocomplete="complement"
            type="text"
            v-model="complement"
            :disabled="loading"
            class="mt-1 shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-slate-300 rounded-md"
            aria-describedby="complement"
          />
        </section>
      </div>
    </div>

    <Button class="w-full" :is-loading="loading">Salvar endereço</Button>
  </form>
</template>
