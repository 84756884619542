<script setup lang="ts">
import MaraCreditCardForm from '&/components/MaraCreditCardForm.vue'
import { currencyFormat } from '&/services/format'
import { type Ref, ref } from 'vue'
import { ArrowSmLeftIcon } from '@heroicons/vue/solid'
import { RouterLink, useRoute } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import {
  CC_PAYMENT_ORDER_URL,
  payWithNewCard,
  payWithStoredCard,
  type StoredCreditCard
} from '&/services/paymentsCreditCard'
import type { InstallmentPlan } from '&/types/installment-plan.types'

const currentCreditCard = ref() as Ref<StoredCreditCard | undefined>
const eventId = uuidv4()

const props = defineProps<{
  total: number
}>()

const route = useRoute()

const loading = ref(false)

async function onPayWithStoredCard(
  cardId: string,
  installmentPlan?: InstallmentPlan,
  threeDSToken?: string
) {
  loading.value = true
  const success = await payWithStoredCard(
    `${CC_PAYMENT_ORDER_URL}/${route.params.orderId}`,
    eventId,
    cardId,
    installmentPlan,
    threeDSToken
  )
  if (success === false) {
    loading.value = false
  }
}

async function onPayWithNewCard(
  fullName: string,
  encryptedCard: string,
  securityCode: string,
  cardBin: string,
  installmentPlan?: InstallmentPlan,
  threeDSToken?: string
) {
  loading.value = true
  const success = await payWithNewCard(
    `${CC_PAYMENT_ORDER_URL}/${route.params.orderId}`,
    eventId,
    {
      encryptedCard,
      securityCode,
      storeCard: true,
      holder: { fullName }
    },
    cardBin,
    installmentPlan,
    threeDSToken
  )
  if (!success) {
    loading.value = false
  }
}
</script>

<template>
  <RouterLink
    class="flex items-center gap-1 text-purple-600 border-b border-slate-200 pb-2 mb-6"
    :to="`/pagamento/${route.params.orderId}`"
  >
    <ArrowSmLeftIcon class="h-5 w-5" />
    <span>Outras formas de pagamento</span>
  </RouterLink>

  <div class="w-full text-center text-4xl font-bold mt-1 mb-6 text-slate-800">
    {{ currencyFormat(props.total) }}
  </div>

  <MaraCreditCardForm
    @pay-with-new-card="onPayWithNewCard"
    @pay-with-stored-card="onPayWithStoredCard"
    :loading="loading"
    v-model:current-credit-card="currentCreditCard"
  />
</template>
