import ky from 'ky'
import { apm } from '&/services/apm'

export const getAddressByPostalCode = async (postalCode: string) => {
  try {
    return await ky.get(`https://opencep.com/v1/${postalCode.replace(/\D/g, '')}`).json<{
      cep?: string
      logradouro?: string
      bairro?: string
      localidade?: string
      uf?: string
    }>()
  } catch (error: any) {
    apm.captureError(error)
    return {}
  }
}
