<script lang="ts" setup>
import { computed } from 'vue'

type Colors = 'white' | 'purple'

interface Props {
  size?: number
  strokeWidth?: number
  color?: Colors
}

const props = withDefaults(defineProps<Props>(), {
  size: 38,
  strokeWidth: 6,
  color: 'purple',
})

const centerPoint = computed(() => {
  return props.size / 2
})

const radius = computed(() => {
  return centerPoint.value - props.strokeWidth / 2
})

const strokeColor = computed(() => {
  const colors = {
    purple: '#7e22ce',
    white: '#ffffff',
  }

  return colors[props.color]
})
</script>

<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    xmlns="http://www.w3.org/2000/svg"
    :stroke="strokeColor"
  >
    <g fill="none" fill-rule="evenodd">
      <g :stroke-width="strokeWidth">
        <circle stroke-opacity=".5" :cx="centerPoint" :cy="centerPoint" :r="radius" />
        <circle
          :cx="centerPoint"
          :cy="centerPoint"
          :r="radius"
          :stroke-dasharray="`${size} ${size * 2}`"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            :from="`0 ${centerPoint} ${centerPoint}`"
            :to="`360 ${centerPoint} ${centerPoint}`"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </svg>
</template>
