<template>
  <div class="p-4 h-full">
    <MaraLoader v-if="loading" />

    <div v-else-if="success" class="text-center flex flex-col justify-center items-center h-full">
      <div class="mb-2 text-slate-600">Coordenadas configuradas corretamente</div>
      <MaraButton href="https://wa.me/5511935017840">Voltar para o WhatsApp</MaraButton>
    </div>

    <div v-else-if="myDeliveryPoint">
      <MapCoordinates :delivery-point="myDeliveryPoint" @success="success = true" />
    </div>

    <div v-else class="text-slate-600 text-center">
      Quer ser um parceiro Mara? <br />
      Acesse
      <a href="https://mercado.mara.com.br/parceiro" class="text-purple-600 hover:underline"
        >https://mercado.mara.com.br/parceiro</a
      >.
    </div>
  </div>
</template>

<script setup lang="ts">
import { api } from '&/services/api'
import MaraLoader from '&/components/MaraLoader.vue'
import { ref } from 'vue'
import MapCoordinates from '@/components/MapCoordinates.vue'
import MaraButton from '&/components/MaraButton.vue'

const loading = ref(true)
const success = ref(false)

const { data } = await api
  .get('users/me', {
    searchParams: {
      fields: [
        'delivery_point_contact.id',
        'delivery_point_contact.address',
        'delivery_point_contact.coordinates'
      ].join(',')
    }
  })
  .json<{
    data: {
      delivery_point_contact?: {
        id: string
        address: {
          postalcode: string
          state: string
          city: string
          neighborhood: string
          street: string
          number: string
          complement: string
        } | null
        coordinates?: { coordinates: [number, number]; type: 'Point' }
      }
    }
  }>()

const myDeliveryPoint = data.delivery_point_contact

loading.value = false
</script>
