<template>
  <div v-if="!paid">
    <div
      class="w-full p-2 bg-purple-800 mb-4 rounded text-white uppercase font-semibold text-sm flex items-center justify-center"
    >
      <ExclamationCircleIcon class="w-5 h-54 inline-block mr-2 align-" />
      <span>Escolha a forma de pagamento:</span>
    </div>

    <div class="flex flex-col justify-around md:justify-start gap-4 mb-4">
      <RouterLink
        :to="`/pagamento/${route.params.orderId}/pix`"
        data-transaction-name="pix"
        class="w-full rounded-lg border border-purple-600 shadow-md flex flex-row gap-2 items-center justify-center flex-grow px-4 py-2 bg-white hover:bg-purple-300 user-select-none !cursor-pointer"
      >
        <QrcodeIcon class="w-7" />
        <span class="text-center text-sm font-semibold leading-none">PIX</span>
      </RouterLink>

      <RouterLink
        :to="`/pagamento/${route.params.orderId}/credit-card`"
        data-transaction-name="credit-card"
        class="w-full rounded-lg border border-purple-600 shadow-md flex flex-row gap-2 items-center justify-center flex-grow px-4 py-2 bg-white hover:bg-purple-300 user-select-none !cursor-pointer"
      >
        <span class="text-center text-sm font-semibold leading-none">Crédito</span>
        <span class="flex gap-1">
          <MaraCreditCardBrandIcon brand="mastercard" class="w-7" />
          <MaraCreditCardBrandIcon brand="visa" class="w-7" />
          <MaraCreditCardBrandIcon brand="elo" class="w-7" />
          <MaraCreditCardBrandIcon brand="flash" class="w-7" />
          <MaraCreditCardBrandIcon brand="caju" class="w-7" />
        </span>
      </RouterLink>

      <RouterLink
        :to="`/pagamento/${route.params.orderId}/credit-card`"
        data-transaction-name="credit-card"
        class="w-full rounded-lg border border-purple-600 shadow-md flex flex-row gap-2 items-center justify-center flex-grow px-4 py-2 bg-white hover:bg-purple-300 user-select-none !cursor-pointer"
      >
        <span class="text-center text-sm font-semibold leading-none">Vale Alimentação</span>
        <span class="flex gap-1">
          <MaraCreditCardBrandIcon brand="sodexo" class="w-7" />
          <MaraCreditCardBrandIcon brand="ticket" class="w-7" />
          <MaraCreditCardBrandIcon brand="pluxee" class="w-7" />
          <MaraCreditCardBrandIcon brand="vr" class="w-7" />
        </span>
      </RouterLink>

      <RouterLink
        v-if="postPaidEnabled"
        :to="`/pagamento/${route.params.orderId}/payment-later`"
        data-transaction-name="payment-later"
        class="w-full rounded-lg border border-purple-600 shadow-md flex flex-row gap-2 items-center justify-center flex-grow px-4 py-2 bg-white hover:bg-purple-300 user-select-none !cursor-pointer"
      >
        <TruckIcon class="w-7" />
        <span class="text-center text-sm font-semibold leading-none"
          >Maquininha ou Dinheiro na Retirada</span
        >
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  QrcodeIcon,
  CreditCardIcon,
  TruckIcon,
  ExclamationCircleIcon
} from '@heroicons/vue/outline'
import { RouterLink, useRoute } from 'vue-router'
import MaraCreditCardBrandIcon from '&/components/MaraCreditCardBrandIcon.vue'

const route = useRoute()

const props = defineProps<{
  total: number
  paid: boolean
  order: {
    total_discount: string
    total_order_price: string
    total_shipping: string
    order_payment_methods_accepted: { postpaid: boolean }[]
  }
  enableCredit: boolean
}>()

const postPaidEnabled = computed(() => {
  return props.order.order_payment_methods_accepted?.[0]?.postpaid
})
</script>
