import { apm } from '../apm'
import { api } from '../api'
import type { Ref } from 'vue'
import type { TaggedAddress } from '&/services/paymentsCreditCard/three3ds'
import type { Address } from '&/types'
import type { Transaction } from '@elastic/apm-rum'
import type { InstallmentPlan } from '&/types/installment-plan.types'

const CC_PAYMENT_BASE_URL = 'backend-endpoints/credit-card-payment'
const CC_SAVE_CARDS_URL = `${CC_PAYMENT_BASE_URL}/saved-cards`
export const CC_PAYMENT_CLUB_MARA_URL = `${CC_PAYMENT_BASE_URL}/club-mara`
export const CC_PAYMENT_ORDER_URL = `${CC_PAYMENT_BASE_URL}/order`

export type StoredCreditCard = {
  current: boolean
  id: string
  brand: string
  bin: string
  lastDigits: string
  pagseguroToken: string
  dateAuthenticated: Date
  date_authenticated?: boolean
  encryptedCardData?: EncryptedCardData
  cardProvider: string
  cardToken: string
}

export interface ChargeEventLog {
  userId?: string
  target?: string
  targetId?: string
  source?: string
  cardType?: string
  authMethod?: string
  cardBin?: string
  amount?: number
  eventId?: string
  step?: string
  error?: string
  status?: string
}

export const BIN_LENGTH = 6

export function binSkips3ds(bin: string) {
  return BINS_TO_SKIP_3DS.map((b) => b.padEnd(BIN_LENGTH, '.')).find((regexBin) =>
    bin.match(regexBin),
  )
}

export const BINS_TO_SKIP_3DS = [
  '40258800', //Others
  '43909400', // Caju
  '457601', // Caju
  '223117', // Flash
  '522731', // Flash
]

export type LabelValue = string | number | boolean | undefined
export type ObjectMap = Record<string, any>

/** FIXME: Refactor this to be generic and not bounded to CC_PAYMENT_FLOW */
export function buildSpammer(
  transaction: Transaction | undefined,
  baseLabels: Record<string, LabelValue>,
): {
  span: (type: string, labels?: Record<string, LabelValue>, context?: ObjectMap) => void
} {
  let currentLabels = { ...baseLabels }
  return {
    span: (
      type: string,
      labels: Record<string, LabelValue> = {},
      context: ObjectMap = {},
      resetPreviousLabels: boolean = false,
    ) => {
      if (resetPreviousLabels) {
        currentLabels = {}
      }

      if (!transaction) {
        console.info('no transaction to build on. ignoring.', type)
        return
      }

      const span = transaction?.startSpan('payments-credit-card', type)

      if (!span) {
        console.error('could not start span', type, labels, context, resetPreviousLabels)
        apm.captureError('could not start span')
        return
      }

      span.addLabels({
        timestamp: new Date().getTime(),
        date: new Date().toLocaleString()?.split(',')[0],
        context: JSON.stringify(context),
        ...currentLabels,
        ...labels,
      })
      currentLabels = { ...currentLabels, ...labels }
    },
  }
}

export async function logChargeEvent(chargeEventLog: ChargeEventLog) {
  try {
    await api
      .post(`backend-endpoints/credit-card-payment/charge-log`, { json: chargeEventLog })
      .json()
  } catch (e: any) {
    apm.captureError(e)
    console.log('could not notify charge event', e, chargeEventLog)
  }
}

export type UnifiedCreditCardPaymentResponse =
  | { paymentStatus?: string; message?: string; failureReason?: string }
  | string

export type PaymentResponse = { paymentStatus: string; clientFailureReason?: string }

/** Each one of these should be used ONLY in one place!!! */
export enum PaymentToUserErrors {
  PAYMENT_STORED_CARD = '001',
  PAYMENT_NEW_CARD = '002',
  ON_PAY_METHOD_NOT_PAID = '003',
  ON_PAY_METHOD_CATCH_1 = '004',
  ON_PAY_METHOD_CATCH_2 = '005',
  ON_PAY_WITH_NEW_CARD_3DS_NOT_SUCCESS = '006',
  ON_PAY_WITH_STORED_CARD_3DS_NOT_SUCCESS = '007',
  CHECKOUT_ON_PAY_WITH_STORED_CARD_3DS_NOT_SUCCESS = '008',
  CHECKOUT_ON_PAY_FAILED_PAYMENT = '009',
}

const normalizeResponse = (response: UnifiedCreditCardPaymentResponse): PaymentResponse => {
  const genericErrorReason = 'Verifique seu cartão e tente novamente.'
  if (typeof response === 'string') {
    const paymentStatus = response
    return {
      paymentStatus: paymentStatus,
      clientFailureReason: paymentStatus !== 'PAID' ? genericErrorReason : undefined,
    }
  }

  if (!!response.message) {
    const paymentStatus = response.message
    return {
      paymentStatus: paymentStatus,
      clientFailureReason: paymentStatus !== 'PAID' ? genericErrorReason : undefined,
    }
  }

  if (response.paymentStatus) {
    return response as PaymentResponse
  }

  const errorMsg = 'invalid response payload'
  apm.captureError(errorMsg)
  throw new Error(errorMsg)
}

export type EncryptedCardData = {
  encryptedCard: string
  securityCode: string
  storeCard: boolean
  holder: { fullName: string }
  card?: {
    bin?: string | undefined
    holderTaxId?: string | undefined
  }
}

export type StoredCardData = {
  userStoredCardId: string
}

type SelectedInstallmentPlan = {
  installmentPlan?: InstallmentPlan
}

export type PaymentData = (EncryptedCardData | StoredCardData) & SelectedInstallmentPlan

async function pay(
  url: string,
  eventId: string,
  cardData: PaymentData,
  bin: string | undefined,
  threeDSToken?: string,
  holderTaxId?: string | undefined,
): Promise<boolean> {
  const friendlyEventId = eventId.substring(0, 4)
  const _pay = apm.startTransaction('credit-card-payment-backend-pay-request')
  _pay?.addLabels({
    url,
    eventId,
    threeDSToken: threeDSToken || 'NO-TOKEN',
    bin: bin || 'NO-BIN',
    friendlyEventId,
  })
  try {
    const payload = {
      eventId,
      ...cardData,
      card: {
        bin: bin,
        holderTaxId: holderTaxId,
      },
      threeDSToken,
      version: '2024-01-19',
    }
    const rawResponse: UnifiedCreditCardPaymentResponse = await api
      .post(url, {
        body: JSON.stringify(payload),
      })
      .json()
    const response = normalizeResponse(rawResponse)
    if (response.paymentStatus === 'ANTIFRAUD_ANALYSIS') {
      return true
    } else if (response.paymentStatus != 'PAID') {
      alert(
        `${
          response.clientFailureReason || 'Tivemos um erro ao processar seu pagamento'
        } - ${friendlyEventId} - ${PaymentToUserErrors.ON_PAY_METHOD_NOT_PAID}`,
      )
      return false
    }
    return true
  } catch (e: any) {
    try {
      const non2xxResponse = await e?.response?.json()
      if (non2xxResponse.clientFailureReason) {
        alert(
          `${non2xxResponse.clientFailureReason} - ${friendlyEventId} - ${PaymentToUserErrors.ON_PAY_METHOD_CATCH_1}`,
        )
        return false
      }
    } catch (e2: any) {
      e2.mara_tracer = friendlyEventId
      apm.captureError(e2)
    }
    e.mara_tracer = friendlyEventId
    alert(
      `Não foi possível concluir seu pagamento, tente novamente daqui a pouco. ${friendlyEventId} - ${PaymentToUserErrors.ON_PAY_METHOD_CATCH_2}`,
    )
    apm.captureError(e)
    return false
  } finally {
    _pay?.end()
  }
}

export function payWithStoredCard(
  url: string,
  eventId: string,
  cardId: string,
  installmentPlan?: InstallmentPlan,
  threeDSToken?: string,
) {
  return pay(
    url,
    eventId,
    { userStoredCardId: cardId, installmentPlan },
    undefined,
    threeDSToken,
    undefined,
  )
}

export function payWithNewCard(
  url: string,
  eventId: string,
  encryptedCardData: EncryptedCardData,
  bin: string,
  installmentPlan?: InstallmentPlan,
  threeDSToken?: string,
) {
  return pay(
    url,
    eventId,
    { ...encryptedCardData, installmentPlan },
    bin,
    threeDSToken,
    encryptedCardData.card?.holderTaxId,
  )
}

export async function loadSavedCards() {
  try {
    const response: {
      total: number
      cards: StoredCreditCard[]
    } = await api.get(CC_SAVE_CARDS_URL).json()
    return response.cards.map(
      (c: Record<string, any>) =>
        ({
          ...c,
          date_authenticated: c.dateAuthenticated && new Date(c.dateAuthenticated),
        } as StoredCreditCard),
    )
  } catch (e: any) {
    apm.captureError(e)
    return []
  }
}

export async function hasPaymentInProcessing(orderId: string): Promise<boolean> {
  const response = await api.get(`backend-endpoints/credit-card-payment/order/${orderId}/status`)

  if (response.status === 404) {
    return false
  }

  const body = (await response.json()) as { paymentStatus: string }
  return body.paymentStatus === 'PROCESSING'
}

export function log3DSError(
  requestData: any,
  me: Ref,
  authResult: { [key: string]: string | undefined },
  error: any,
  step: string,
  cardId: string,
  isExistingCard: boolean,
  cardBin?: string,
) {
  const currentUrl = window.location.toString()
  const params = {
    currentUrl,
    cardId,
    cardBin,
    isExistingCard,
    step,
    userId: me.value.id,
    requestData,
    authResult,
  }

  if (error) {
    error.maraDebugData = params
    error.maraDebugDataStr = JSON.stringify(params)
    apm.captureError(error)
  } else {
    apm.captureError(JSON.stringify(params))
  }
}

export function getBillingAddress(
  myAddress?: Address,
  orderAddress?: Address,
): TaggedAddress | undefined {
  if (myAddress) {
    return { ...myAddress, _source: 'me' }
  }

  if (orderAddress) {
    return { ...orderAddress, _source: 'order' }
  }

  return undefined
}

export interface OrderChargeData {
  address: any
  total_discount: string
  total_order_price: string
  total_shipping: string
  delivery_point: string
}

export const FAILED_PAYMENT_ERROR_MSG = `Não foi possível efetuar o pagamento. Tente mais tarde novamente. Erro:`
export const FAILED_3DS_ERROR_MSG = `Não foi possível validar seu cartão. Tente com outro cartão ou troque o meio de pagamento. Erro:`
