export enum CardProvider {
  PAGAR_ME = 'PAGAR_ME',
  PAG_SEGURO = 'PAG_SEGURO',
}

export interface CardData {
  number: string
  holderName: string
  holderDocument: string
  expirationMM: string
  expirationYY: string
  cvv: string
  bin: string
  lastDigits: string
  brand: string
}

export interface TokenizedCard {
  cardToken: string
  cardProvider: CardProvider
}
