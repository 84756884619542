import { createRouter, createWebHistory } from 'vue-router'
import PaymentOrderView from '../views/PaymentOrderView.vue'
import PaymentSelectMethod from '../views/PaymentSelectMethod.vue'
import PaymentPixView from '../views/PaymentPixView.vue'
import PaymentCreditCardView from '../views/PaymentCreditCardView.vue'
import PaymentLaterView from '../views/PaymentLaterView.vue'
import DeliveryPointView from '../views/DeliveryPointView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/meu-ponto-de-entrega/coordenadas',
      name: 'deliveryPoint',
      component: DeliveryPointView
    },
    {
      path: '/pagamento/:orderId/',
      name: 'paymentOrder',
      component: PaymentOrderView,
      children: [
        {
          path: '',
          name: 'select-method',
          component: PaymentSelectMethod
        },
        {
          path: 'pix',
          name: 'pix',
          component: PaymentPixView
        },
        {
          path: 'credit-card',
          name: 'credit-card',
          component: PaymentCreditCardView
        },
        {
          path: 'payment-later',
          name: 'payment-later',
          component: PaymentLaterView
        }
      ]
    }
  ]
})

export default router
