<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    id="svg859"
    version="1.1"
    viewBox="0 0 237.76514 84.263428"
    height="84.263428mm"
    width="237.76514mm"
  >
    <defs id="defs853">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1420">
        <path d="M 0,1080 H 1920 V 0 H 0 Z" id="path1418" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1428">
        <path d="M 2.31224,1078.65 H 1921.71 V 0.354599 H 2.31224 Z" id="path1426" />
      </clipPath>
      <radialGradient
        fx="0"
        fy="0"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2279.4458,0,0,1280.5419,962.0127,539.50366)"
        spreadMethod="pad"
        id="radialGradient1438"
      >
        <stop style="stop-opacity: 1; stop-color: #ffffff" offset="0" id="stop1434" />
        <stop style="stop-opacity: 1; stop-color: #000000" offset="1" id="stop1436" />
      </radialGradient>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1460">
        <path d="M 1.52588e-5,1083.19 H 1921.71 V -3.05673 H 1.52588e-5 Z" id="path1458" />
      </clipPath>
      <radialGradient
        fx="0"
        fy="0"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2282.1917,0,0,1289.9785,960.85657,540.06549)"
        spreadMethod="pad"
        id="radialGradient1470"
      >
        <stop style="stop-opacity: 1; stop-color: #ffffff" offset="0" id="stop1466" />
        <stop style="stop-opacity: 1; stop-color: #000000" offset="1" id="stop1468" />
      </radialGradient>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1492">
        <path d="M 11.7831,1078.21 H 1910.67 V 42.0222 H 11.7831 Z" id="path1490" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1496">
        <path d="M 11.7831,1017.59 H 1879.91 V 42.0222 H 11.7831 Z" id="path1494" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1500">
        <path d="M 121.596,1078.21 H 1910.67 V 117.8 H 121.596 Z" id="path1498" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2268">
        <path d="M 0,1080 H 1920 V 0 H 0 Z" id="path2266" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2280">
        <path d="m 1609.83,82.2588 h 26.48 V 63.5831 h -26.48 z" id="path2278" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2296">
        <path d="m 1600.18,103.19 h 22.86 V 76.7676 h -22.86 z" id="path2294" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2312">
        <path d="m 1618.19,89.9323 h 22.12 V 63.6456 h -22.12 z" id="path2310" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2328">
        <path d="m 1604.2,103.117 h 26.46 V 84.441 h -26.46 z" id="path2326" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2474">
        <path d="M 0,1080 H 1920 V 0 H 0 Z" id="path2472" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2482">
        <path d="M 1258.575,-1094.026 926.826,-9.01 H 0 v -1085.016 z" id="path2480" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2490">
        <path d="M -307.943,-1106.382 H 1369.67 V 12.027 H -307.943 Z" id="path2488" />
      </clipPath>
    </defs>
    <metadata id="metadata856">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g transform="translate(-535.59399,-20.808825)" id="layer1">
      <path
        d="m 633.42119,99.489186 v -48.3242 c 0,-8.89177 7.20795,-16.09972 16.09936,-16.09972 l 14.2681,0.0215 c 8.86566,0.0176 16.04363,7.20972 16.04363,16.07573 v 10.28594 c 0,8.89176 -7.20831,16.09972 -16.09972,16.09972 h -20.1616"
        style="
          fill: none;
          stroke: #939598;
          stroke-width: 2.97638607;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path2356"
      />
      <path
        d="m 683.81948,35.058846 h 6.18913 c 3.64913,0 6.60682,2.95804 6.60682,6.60717 v 36.09834"
        style="
          fill: none;
          stroke: #939598;
          stroke-width: 2.97638607;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path2360"
      />
      <path
        d="m 695.28853,29.466256 -2.8067,-2.807053 c -0.69674,-0.696383 -0.69674,-1.825625 0,-2.522008 l 2.80494,-2.805289 c 0.69779,-0.697441 1.82844,-0.697441 2.52553,0 l 2.80494,2.805289 c 0.69673,0.696383 0.69673,1.825625 0,2.522008 l -2.8067,2.807053 c -0.69638,0.69638 -1.82527,0.69638 -2.52201,0"
        style="
          fill: #32bcad;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2364"
      />
      <path
        d="m 708.48944,35.026636 h 6.13798 c 3.15771,0 6.18596,1.25448 8.41834,3.48686 l 14.35664,14.35664 c 1.85949,1.85984 4.87468,1.85984 6.73453,0 l 14.30408,-14.30408 c 2.23273,-2.23238 5.26062,-3.48686 8.41833,-3.48686 h 4.9904"
        style="
          fill: none;
          stroke: #939598;
          stroke-width: 2.97638607;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path2368"
      />
      <path
        d="m 708.48944,77.448336 h 6.13798 c 3.15771,0 6.18596,-1.25448 8.41834,-3.48686 l 14.35664,-14.35664 c 1.85949,-1.85984 4.87468,-1.85984 6.73453,0 l 14.30408,14.30408 c 2.23273,2.23238 5.26062,3.48686 8.41833,3.48686 h 4.9904"
        style="
          fill: none;
          stroke: #939598;
          stroke-width: 2.97638607;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path2372"
      />
      <path
        d="m 596.82737,86.620206 c -3.08045,0 -5.97782,-1.19944 -8.15622,-3.37679 l -11.77678,-11.77713 c -0.82691,-0.82903 -2.26801,-0.82656 -3.09456,0 l -11.81982,11.82017 c -2.17841,2.17734 -5.07577,3.37679 -8.15623,3.37679 h -2.32092 l 14.9158,14.915444 c 4.65807,4.65808 12.21069,4.65808 16.86912,0 l 14.95813,-14.958484 z"
        style="
          fill: #32bcad;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2376"
      />
      <path
        d="m 553.82362,44.963326 c 3.08046,0 5.97782,1.19944 8.15622,3.37679 l 11.81982,11.82193 c 0.85125,0.85161 2.2412,0.85479 3.09457,-10e-4 l 11.77678,-11.77784 c 2.1784,-2.17735 5.07576,-3.37679 8.15622,-3.37679 h 1.41852 l -14.95778,-14.95813 c -4.65878,-4.658432 -12.2114,-4.658432 -16.86948,0 l -14.91509,14.91509 z"
        style="
          fill: #32bcad;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2380"
      />
      <path
        d="m 610.61844,57.378776 -9.03922,-9.03922 c -0.19897,0.0797 -0.41452,0.12946 -0.64206,0.12946 h -4.10986 c -2.12478,0 -4.20476,0.86184 -5.70618,2.36432 l -11.77643,11.77678 c -1.10207,1.10208 -2.55022,1.65347 -3.99697,1.65347 -1.44815,0 -2.89524,-0.55139 -3.99697,-1.65241 l -11.82088,-11.82088 c -1.50142,-1.50283 -3.5814,-2.36431 -5.70618,-2.36431 h -5.05354 c -0.21555,0 -0.41698,-0.0508 -0.60713,-0.12242 l -9.07521,9.07521 c -4.65843,4.65843 -4.65843,12.2107 0,16.86913 l 9.07486,9.07485 c 0.1905,-0.0716 0.39193,-0.12241 0.60748,-0.12241 h 5.05354 c 2.12478,0 4.20476,-0.86148 5.70618,-2.36396 l 11.81982,-11.81982 c 2.13643,-2.13466 5.8607,-2.13537 7.995,0.001 l 11.77643,11.77573 c 1.50142,1.50248 3.5814,2.36431 5.70618,2.36431 h 4.10986 c 0.22754,0 0.44309,0.0497 0.64206,0.12947 l 9.03922,-9.03922 c 4.65808,-4.65843 4.65808,-12.2107 0,-16.86913"
        style="
          fill: #32bcad;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2384"
      />
      <path
        d="m 645.6909,95.381446 c -0.6671,0 -1.44356,0.16051 -2.21156,0.33761 v 2.94463 c 0.53199,0.19438 1.13947,0.28787 1.72191,0.28787 1.47673,0 2.17699,-0.49812 2.17699,-1.79881 0,-1.22273 -0.57362,-1.7713 -1.68734,-1.7713 m -2.70968,5.468764 v -5.823654 h 0.40534 l 0.0423,0.25364 c 0.68333,-0.16051 1.62842,-0.37147 2.30364,-0.37147 0.54927,0 1.07209,0.0836 1.51059,0.4385 0.50694,0.41416 0.66711,1.08021 0.66711,1.80552 0,0.76094 -0.25365,1.47778 -0.94545,1.87395 -0.48084,0.27023 -1.13065,0.37994 -1.71309,0.37994 -0.59937,0 -1.17298,-0.0931 -1.77235,-0.26987 v 1.713444 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2388"
      />
      <path
        d="m 651.61782,95.363876 c -1.47708,0 -2.13537,0.46461 -2.13537,1.76424 0,1.2573 0.64982,1.82316 2.13537,1.82316 1.46826,0 2.12654,-0.45614 2.12654,-1.75578 0,-1.2573 -0.64946,-1.83162 -2.12654,-1.83162 m 1.89865,3.5874 c -0.48966,0.35383 -1.14759,0.45543 -1.89865,0.45543 -0.768,0 -1.42664,-0.10971 -1.90747,-0.45543 -0.5401,-0.37959 -0.75989,-1.00471 -0.75989,-1.78894 0,-0.77717 0.21979,-1.40935 0.75989,-1.79846 0.48083,-0.34537 1.13947,-0.45544 1.90747,-0.45544 0.75918,0 1.40899,0.11007 1.89865,0.45544 0.54892,0.38911 0.75953,1.02129 0.75953,1.78894 0,0.78563 -0.21943,1.41887 -0.75953,1.79846"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2392"
      />
      <path
        d="m 660.50757,99.288706 -1.64571,-3.53554 h -0.0342 l -1.61995,3.53554 h -0.44732 l -1.75543,-4.26226 h 0.54857 l 1.46015,3.57822 h 0.0339 l 1.58609,-3.57822 h 0.45579 l 1.62912,3.57822 h 0.0339 l 1.42628,-3.57822 h 0.53129 l -1.75507,4.26226 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2396"
      />
      <path
        d="m 665.8936,95.355586 c -1.36701,0 -1.83126,0.60748 -1.91593,1.4859 h 3.83187 c -0.042,-0.97049 -0.54045,-1.4859 -1.91594,-1.4859 m -0.0166,4.05095 c -0.81915,0 -1.35043,-0.11783 -1.77235,-0.47273 -0.49812,-0.43038 -0.6671,-1.05445 -0.6671,-1.77165 0,-0.68368 0.22824,-1.40934 0.79375,-1.82315 0.47237,-0.32879 1.0548,-0.43039 1.66229,-0.43039 0.54892,0 1.1818,0.0589 1.70462,0.41381 0.6163,0.4131 0.73483,1.13947 0.73483,1.96603 h -4.37197 c 0.0166,0.87736 0.30374,1.65453 1.95756,1.65453 0.78529,0 1.51942,-0.127 2.2031,-0.24518 v 0.44697 c -0.70908,0.12735 -1.49401,0.26176 -2.24473,0.26176"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2400"
      />
      <path
        d="m 669.76178,99.288706 v -4.26226 h 0.40499 l 0.0427,0.25365 c 0.90276,-0.22755 1.32468,-0.37148 2.11808,-0.37148 h 0.0593 v 0.47272 h -0.11854 c -0.66639,0 -1.07138,0.0924 -2.00801,0.33761 v 3.56976 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2404"
      />
      <path
        d="m 675.27876,95.355586 c -1.36701,0 -1.83127,0.60748 -1.91593,1.4859 h 3.83187 c -0.042,-0.97049 -0.54046,-1.4859 -1.91594,-1.4859 m -0.0166,4.05095 c -0.81915,0 -1.35043,-0.11783 -1.77235,-0.47273 -0.49848,-0.43038 -0.66711,-1.05445 -0.66711,-1.77165 0,-0.68368 0.22825,-1.40934 0.79375,-1.82315 0.47237,-0.32879 1.05481,-0.43039 1.66229,-0.43039 0.54892,0 1.18181,0.0589 1.70462,0.41381 0.61631,0.4131 0.73484,1.13947 0.73484,1.96603 h -4.37197 c 0.0166,0.87736 0.30374,1.65453 1.95756,1.65453 0.78493,0 1.51906,-0.127 2.2031,-0.24518 v 0.44697 c -0.70909,0.12735 -1.49402,0.26176 -2.24473,0.26176"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2408"
      />
      <path
        d="m 683.17284,95.651526 c -0.53164,-0.19438 -1.13912,-0.28751 -1.72156,-0.28751 -1.47673,0 -2.1777,0.49882 -2.1777,1.7981 0,1.23155 0.57397,1.77165 1.68769,1.77165 0.6671,0 1.44357,-0.16051 2.21157,-0.32914 z m 0.0931,3.63714 -0.0423,-0.25365 c -0.68369,0.16052 -1.62913,0.37183 -2.30435,0.37183 -0.54786,0 -1.07174,-0.0759 -1.51059,-0.43886 -0.50624,-0.4138 -0.66675,-1.08055 -0.66675,-1.80587 0,-0.75953 0.25329,-1.47743 0.94509,-1.86548 0.48119,-0.27835 1.131,-0.38806 1.72191,-0.38806 0.5909,0 1.16487,0.1016 1.76389,0.27023 v -1.94945 h 0.49812 v 6.05931 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2412"
      />
      <path
        d="m 690.97215,95.381446 c -0.6671,0 -1.44356,0.16051 -2.21156,0.33761 v 2.93652 c 0.54046,0.20249 1.13947,0.29598 1.72191,0.29598 1.47673,0 2.17699,-0.49812 2.17699,-1.79881 0,-1.22273 -0.57362,-1.7713 -1.68734,-1.7713 m 1.27424,3.64525 c -0.48119,0.27023 -1.13101,0.37994 -1.71344,0.37994 -0.63289,0 -1.26577,-0.10971 -1.90712,-0.32067 l -0.0254,0.20285 h -0.33796 v -6.05967 h 0.49812 v 2.03341 c 0.68368,-0.15098 1.60337,-0.35383 2.25319,-0.35383 0.54928,0 1.07209,0.0836 1.5106,0.4385 0.50694,0.41416 0.6671,1.08021 0.6671,1.80552 0,0.76094 -0.25365,1.47778 -0.94509,1.87395"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2416"
      />
      <path
        d="m 693.85227,100.92563 v -0.46355 c 0.24447,0.0247 0.47307,0.0423 0.63323,0.0423 0.61631,0 0.98707,-0.1778 1.33315,-0.878414 l 0.16051,-0.33726 -2.22779,-4.26226 h 0.57397 l 1.90747,3.67947 h 0.0335 l 1.81434,-3.67947 h 0.5655 l -2.39677,4.78578 c -0.43886,0.869254 -0.91158,1.155704 -1.78082,1.155704 -0.19439,0 -0.40499,-0.0166 -0.61631,-0.0423"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2420"
      />
      <path
        d="m 705.5091,96.857996 h -1.65382 v 1.49437 h 1.66194 c 1.13947,0 1.57021,-0.12736 1.57021,-0.75142 0,-0.66746 -0.59055,-0.74295 -1.57833,-0.74295 m -0.30339,-2.42217 h -1.35043 v 1.51871 h 1.35855 c 1.12254,0 1.56951,-0.13441 1.56951,-0.76765 0,-0.67451 -0.56515,-0.75106 -1.57763,-0.75106 m 2.5654,4.44817 c -0.60819,0.38806 -1.34232,0.40464 -2.68393,0.40464 h -2.52342 v -5.78097 h 2.46451 c 1.15605,0 1.86478,0.0166 2.45568,0.37147 0.42228,0.2533 0.59055,0.64135 0.59055,1.14759 0,0.60713 -0.25259,1.01283 -0.91158,1.28305 v 0.0332 c 0.74331,0.16969 1.22414,0.54928 1.22414,1.36772 0,0.55669 -0.20249,0.9197 -0.61595,1.17334"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2424"
      />
      <path
        d="m 713.43591,97.499666 c -0.49847,-0.0427 -1.00436,-0.0674 -1.53599,-0.0674 -0.86925,0 -1.17369,0.17709 -1.17369,0.57326 0,0.37148 0.25364,0.57433 0.92004,0.57433 0.55704,0 1.22379,-0.1263 1.78964,-0.25365 z m 0.25294,1.78894 -0.0339,-0.2533 c -0.72601,0.1778 -1.5695,0.37148 -2.31245,0.37148 -0.45615,0 -0.9451,-0.0593 -1.29152,-0.31256 -0.31997,-0.22755 -0.47237,-0.59902 -0.47237,-1.02941 0,-0.48154 0.21131,-0.92851 0.71719,-1.15605 0.44733,-0.21096 1.0467,-0.22754 1.59562,-0.22754 0.44697,0 1.04598,0.0247 1.54446,0.0589 v -0.0765 c 0,-0.6664 -0.43921,-0.88583 -1.63759,-0.88583 -0.46426,0 -1.02976,0.0247 -1.56987,0.0755 v -0.86082 c 0.59902,-0.0497 1.27459,-0.084 1.83163,-0.084 0.74224,0 1.51094,0.0593 1.98331,0.39652 0.48895,0.34643 0.58244,0.82762 0.58244,1.45979 v 2.52378 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2428"
      />
      <path
        d="m 720.19002,99.288706 v -2.35514 c 0,-0.77576 -0.39617,-1.05446 -1.10561,-1.05446 -0.52281,0 -1.1811,0.13476 -1.73848,0.27023 v 3.13937 h -1.18992 v -4.26226 h 0.97049 l 0.0423,0.27023 c 0.75071,-0.19368 1.58679,-0.38806 2.27894,-0.38806 0.52282,0 1.05481,0.0755 1.4598,0.43886 0.33725,0.30409 0.46425,0.72531 0.46425,1.3335 v 2.60773 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2432"
      />
      <path
        d="m 724.73376,99.406676 c -0.54857,0 -1.14829,-0.0755 -1.58679,-0.44697 -0.52317,-0.42227 -0.67522,-1.08867 -0.67522,-1.80693 0,-0.67451 0.21943,-1.40899 0.86924,-1.82209 0.53199,-0.34643 1.18992,-0.42193 1.87361,-0.42193 0.48965,0 0.97084,0.0339 1.50213,0.0836 v 0.91158 c -0.43075,-0.0413 -0.94545,-0.0755 -1.35855,-0.0755 -1.13136,0 -1.66264,0.35489 -1.66264,1.33385 0,0.92004 0.39652,1.31621 1.32468,1.31621 0.5401,0 1.17369,-0.10125 1.78964,-0.21943 v 0.87736 c -0.6671,0.13582 -1.39277,0.27023 -2.0761,0.27023"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2436"
      />
      <path
        d="m 730.3248,95.802586 c -1.13101,0 -1.62913,0.35489 -1.62913,1.32539 0,0.97084 0.48965,1.38465 1.62913,1.38465 1.12218,0 1.61148,-0.34678 1.61148,-1.31727 0,-0.9705 -0.48048,-1.39277 -1.61148,-1.39277 m 2.04223,3.15701 c -0.52317,0.35383 -1.20686,0.44697 -2.04223,0.44697 -0.85267,0 -1.536,-0.10125 -2.0507,-0.44697 -0.5909,-0.38806 -0.80222,-1.02941 -0.80222,-1.7974 0,-0.76871 0.21132,-1.41852 0.80222,-1.80658 0.5147,-0.34572 1.19803,-0.44697 2.0507,-0.44697 0.84419,0 1.51906,0.10125 2.04223,0.44697 0.5909,0.38806 0.79339,1.03787 0.79339,1.7974 0,0.76871 -0.21096,1.41852 -0.79339,1.80658"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2440"
      />
      <path
        d="m 740.03066,99.406676 c -0.71684,0 -1.4933,-0.11783 -2.07609,-0.59902 -0.6918,-0.57432 -0.90276,-1.46014 -0.90276,-2.41441 0,-0.8516 0.26987,-1.86443 1.17299,-2.45498 0.70026,-0.45543 1.5695,-0.54857 2.44721,-0.54857 0.64206,0 1.29999,0.0423 2.01754,0.10125 v 1.03787 c -0.6163,-0.0508 -1.37548,-0.0931 -1.96638,-0.0931 -1.64606,0 -2.34633,0.62512 -2.34633,1.95756 0,1.35996 0.64947,1.96744 1.86514,1.96744 0.79304,0 1.67922,-0.16051 2.57386,-0.34678 v 1.02941 c -0.89464,0.17815 -1.83162,0.36336 -2.78518,0.36336"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2444"
      />
      <path
        d="m 746.31279,95.668076 c -0.98778,0 -1.36772,0.35489 -1.44357,1.00471 h 2.86985 c -0.0342,-0.69215 -0.43921,-1.00471 -1.42628,-1.00471 m -0.1778,3.73874 c -0.70026,0 -1.33315,-0.084 -1.80587,-0.47308 -0.50624,-0.42121 -0.68368,-1.05445 -0.68368,-1.78082 0,-0.64982 0.21131,-1.37513 0.80221,-1.7974 0.52282,-0.37112 1.18992,-0.44697 1.86514,-0.44697 0.60748,0 1.32503,0.0674 1.84785,0.43039 0.68404,0.48119 0.74295,1.22414 0.75106,2.1015 h -4.05059 c 0.025,0.65016 0.37112,1.07209 1.56951,1.07209 0.7426,0 1.56951,-0.10972 2.27048,-0.21943 v 0.83538 c -0.8188,0.13546 -1.71345,0.27834 -2.56611,0.27834"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2448"
      />
      <path
        d="m 754.20619,99.288706 v -2.35514 c 0,-0.77576 -0.39617,-1.05446 -1.1056,-1.05446 -0.52317,0 -1.1811,0.13476 -1.73849,0.27023 v 3.13937 h -1.18992 v -4.26226 h 0.97049 l 0.0423,0.27023 c 0.75071,-0.19368 1.5868,-0.38806 2.27895,-0.38806 0.52281,0 1.0548,0.0755 1.45979,0.43886 0.33726,0.30409 0.46426,0.72531 0.46426,1.3335 v 2.60773 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2452"
      />
      <path
        d="m 758.77509,99.406676 c -0.57362,0 -1.09714,-0.16051 -1.38395,-0.60748 -0.21096,-0.3041 -0.31256,-0.71685 -0.31256,-1.29117 v -1.59561 h -0.86078 v -0.88583 h 0.86078 l 0.127,-1.29152 h 1.05481 v 1.29152 h 1.67922 v 0.88583 h -1.67922 v 1.36772 c 0,0.32914 0.025,0.60748 0.11782,0.81033 0.12665,0.2868 0.40499,0.39617 0.77647,0.39617 0.27834,0 0.6163,-0.0423 0.85231,-0.0836 v 0.8516 c -0.38806,0.0766 -0.83573,0.15205 -1.2319,0.15205"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2456"
      />
      <path
        d="m 761.10053,99.288706 v -4.26226 h 0.97084 l 0.0423,0.27023 c 0.78493,-0.21943 1.36702,-0.38806 2.10997,-0.38806 0.0335,0 0.0843,0 0.15134,0.008 v 1.01317 c -0.13512,-0.008 -0.29528,-0.008 -0.41346,-0.008 -0.58243,0 -1.02094,0.067 -1.67111,0.21943 v 3.14748 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2460"
      />
      <path
        d="m 768.70144,97.499666 c -0.49812,-0.0427 -1.00435,-0.0674 -1.53599,-0.0674 -0.86925,0 -1.17369,0.17709 -1.17369,0.57326 0,0.37148 0.25364,0.57433 0.92004,0.57433 0.55739,0 1.22379,-0.1263 1.78964,-0.25365 z m 0.25295,1.78894 -0.0335,-0.2533 c -0.72602,0.1778 -1.56987,0.37148 -2.31282,0.37148 -0.45578,0 -0.94509,-0.0593 -1.29152,-0.31256 -0.31996,-0.22755 -0.47236,-0.59902 -0.47236,-1.02941 0,-0.48154 0.21131,-0.92851 0.71755,-1.15605 0.44732,-0.21096 1.04633,-0.22754 1.59526,-0.22754 0.44732,0 1.04634,0.0247 1.54446,0.0589 v -0.0765 c 0,-0.6664 -0.43921,-0.88583 -1.6376,-0.88583 -0.4639,0 -1.02976,0.0247 -1.56986,0.0755 v -0.86082 c 0.59902,-0.0497 1.27459,-0.084 1.83198,-0.084 0.74224,0 1.51059,0.0593 1.98296,0.39652 0.4893,0.34643 0.58244,0.82762 0.58244,1.45979 v 2.52378 z"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        id="path2464"
      />
      <path
        id="path2466"
        style="
          fill: #939598;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.35277778;
        "
        d="m 771.42178,93.229356 h 1.18992 v 6.05931 h -1.18992 z"
      />
    </g>
  </svg>
</template>
