import type { CardData, TokenizedCard } from '../type'
import { CardProvider } from '../type'
import ky from 'ky-universal'

function getPagarmePublicKey() {
  return process.env.NODE_ENV === 'production' ? 'pk_y2lYqLd7ujTxqreD' : 'pk_test_9L6gOrEcnFe8ZYGV'
}

export async function tokenizeVoucher(cardData: CardData): Promise<TokenizedCard> {
  const payload = {
    card: {
      number: cardData.number,
      holder_name: cardData.holderName,
      holder_document: cardData.holderDocument,
      exp_month: cardData.expirationMM,
      exp_year: cardData.expirationYY,
      cvv: cardData.cvv,
      label: 'Voucher',
    },
    type: 'card',
  }
  const url = `https://api.pagar.me/core/v5/tokens?appId=${getPagarmePublicKey()}`
  const response = (await ky.post(url, { json: payload }).json()) as any

  return {
    cardProvider: CardProvider.PAGAR_ME,
    cardToken: response.id,
  }
}
