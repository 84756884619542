<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import pin from '../assets/pin.png'
import MaraButton from '&/components/MaraButton.vue'
import { api } from '&/services/api'
import ky from 'ky'

interface Props {
  deliveryPoint: {
    id: string
    address: {
      postalcode: string
      state: string
      city: string
      neighborhood: string
      street: string
      number: string
      complement: string
    } | null
    coordinates?: { coordinates: [number, number]; type: 'Point' }
  }
}

interface DeliveryPointOptions {
  name: string
  address: {
    postalcode: string
    state: string
    city: string
    neighborhood: string
    street: string
    number: string
    complement: string
  }
  coordinates: {
    lat: number
    lng: number
  }
  opening_hours: string
  contact?: string[]
  payment_contact?: string
  pix_payment_key: string
}

const props = defineProps<Props>()

async function getCoordinates(address: {
  postalcode: string
  state: string
  city: string
  neighborhood: string
  street: string
  number: string
}) {
  const { results } = await ky
    .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      searchParams: {
        address: `${address.street}, ${address.number}, ${address.neighborhood} - ${address.city}, ${address.state}, Brasil - ${address.postalcode}`,
        key: apiKey
      }
    })
    .json<{
      results: [
        {
          geometry: {
            location: {
              lat: number
              lng: number
            }
          }
        }
      ]
    }>()

  return results?.[0].geometry.location
}

let defaultCoordinates = {
  lat: -23.5507872,
  lng: -46.633632
}

if (!props.deliveryPoint.coordinates && props.deliveryPoint.address) {
  defaultCoordinates = await getCoordinates(props.deliveryPoint.address)
}

const loadingButton = ref(false)

const emit = defineEmits<{
  (e: 'success'): void
}>()

const apiKey = 'AIzaSyDXoMSSXuaM6glFXN36UUdh-tPt4uWnPfs'

const loader = new Loader({ apiKey, version: 'weekly' })

const map = ref()
const dragging = ref(false)

onMounted(async () => {
  const google = await loader.load()

  const styledMap = new google.maps.StyledMapType([
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    }
  ])

  const lat = props.deliveryPoint.coordinates?.coordinates[1] || defaultCoordinates.lat
  const lng = props.deliveryPoint.coordinates?.coordinates[0] || defaultCoordinates.lng

  map.value = new google.maps.Map(document.getElementById('map'), {
    center: { lat, lng },
    zoom: 18,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    gestureHandling: 'greedy'
  })

  map.value.mapTypes.set('styled_map', styledMap)
  map.value.setMapTypeId('styled_map')

  map.value.addListener('dragend', () => {
    dragging.value = false
  })

  map.value.addListener('dragstart', () => {
    dragging.value = true
  })

  map.value.addListener('dragend', () => {
    dragging.value = false
  })
})

async function updateDeliveryPoints(
  id: string,
  deliveryPointOptions: Pick<DeliveryPointOptions, 'coordinates'>
) {
  return await api
    .patch(`items/delivery_points/${id}`, {
      body: JSON.stringify({
        coordinates: {
          coordinates: [deliveryPointOptions.coordinates.lng, deliveryPointOptions.coordinates.lat],
          type: 'Point'
        }
      }),
      searchParams: {
        fields: 'id'
      }
    })
    .json<{
      data: {
        id: string
      }
    }>()
}

async function onSubmit() {
  loadingButton.value = true
  await updateDeliveryPoints(props.deliveryPoint.id, {
    coordinates: { lat: map.value.center.lat(), lng: map.value.center.lng() }
  })
  emit('success')
  loadingButton.value = false
}
</script>

<template>
  <div class="absolute inset-0 flex justify-center items-center">
    <img
      :src="pin"
      class="block h-[29px] w-[24px] -mt-[28px] z-10 select-none pointer-events-none"
      :class="{ 'grayscale opacity-30': dragging }"
      draggable="false"
    />

    <div id="map" class="absolute inset-0"></div>
  </div>
  <div class="absolute inset-4 top-auto">
    <MaraButton class="w-full" size="lg" @click="onSubmit" :loading="loadingButton"
      >Confirmar o ponto da sua loja</MaraButton
    >
  </div>
</template>
