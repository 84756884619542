export const reaisUpToCentsCurrencyFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format as (v: number) => string

export const reaisUpToThousandsCurrencyFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
}).format as (v: number) => string

export const currencyFormat = (v: number, displaySmallAmounts = false) => {
  if (!displaySmallAmounts || Math.abs(v) >= 0.01) {
    return reaisUpToCentsCurrencyFormat(v)
  } else if (Math.abs(v) >= 0.0001) {
    return reaisUpToThousandsCurrencyFormat(v)
  }
}

export const phoneFormat = (phoneNumber: string) => {
  return phoneNumber.replace(/^(55)(\d{2})(\d{4,5})(\d{4})$/, '($2) $3-$4')
}

export const dateFormat = (value: string | Date) => {
  if (!value) value

  if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
    value = `${value}Z-3`
  }

  const date = value instanceof Date ? value : new Date(value)
  return new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
  }).format(date)
}

export const timeFormat = (value: string | Date) => {
  if (!value) return value

  if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
    value = `${value}Z-3`
  }

  const date = value instanceof Date ? value : new Date(value)
  return new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date)
}

export const dateFormatDay = (value: string | Date) => {
  if (!value) value

  let dateObj = value instanceof Date ? value : new Date(value)
  dateObj.setHours(dateObj.getHours() + 3)

  const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long' }
  const formattedDate = new Intl.DateTimeFormat('pt-BR', options).format(dateObj)

  return formattedDate.replace('-feira', '')
}

export function pluralize(value: number, singular: string, plural: string) {
  return value === 1 ? singular : plural
}

export function intQuantityPluralized(amount: number, singular: string, plural: string) {
  return `${Math.floor(amount)} ${pluralize(amount, singular, plural)}`
}
