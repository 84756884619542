import { init as initApm, type Transaction } from '@elastic/apm-rum'

const apm = initApm({
  serviceName: import.meta.env.SSR ? 'ssr' : location.hostname.replace('.mara.com.br', ''),
  serverUrl: 'https://3b89d874858b402badc65d45cd699807.apm.us-east-1.aws.cloud.es.io:443',
  environment: 'production',
  active: import.meta.env.SSR ? false : location.hostname.includes('mara.com.br'),
  distributedTracingOrigins: ['https://directus.mara.com.br'],
  propagateTracestate: true,
})

export { apm, type Transaction }
